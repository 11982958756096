import { Typography } from "@mui/material";
import LabelIcon from "../../features/nodes/NodeCardLabelIcon";

export default function LabelNotice(props: { color: string; text: string }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 20,
        }}
      >
        <LabelIcon color={props.color} />

        <Typography fontWeight={600} color="text.secondary">{props.text}</Typography>
      </div>
    </>
  );
}
