import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_usePrompt } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  EdgeTypes,
  Node,
  NodeDragHandler,
  NodeTypes,
  OnSelectionChangeParams,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  XYPosition
} from "reactflow";
import { v4 as uuid } from "uuid";

import { Box, CircularProgress } from "@mui/material";

import { useStore } from "../../app/stores/store";

import { EdgeData } from "../../app/models/edgeData";
import CustomEdge from "./customs/CustomEdge";

import "reactflow/dist/style.css";
import { GraphNodeData } from "../../app/models/graphData";
import { NodeData } from "../../app/models/nodeData";
import CustomNode from "./customs/CustomNode";
import GraphEditorDetails from "./GraphEditorDetails";
import GraphEditorTools from "./GraphEditorTools";
import "./staticReactFlowEditor.css";

const reactFlowEdgeTypes = {
  default: CustomEdge,
} as EdgeTypes;

const reactFlowNodeTypes = {
  default: CustomNode,
} as NodeTypes;

export default observer(function StaticReactFlowEditor(): any {
  const { graphStore, boardStore } = useStore();

  const {
    edgeIdsToBeDeleted,
    selectedNode,
    selectedEdge,
  } = graphStore;
  const { selectedBoardId, selectedBoard } = boardStore;

  const reactFlowWrapper: any = useRef(null);
  const [reactFlowInstance, setReactFlowInstance]: any = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState<GraphNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>([]);

  // sync graphStore data with reactflow state
  useEffect(() => {
    graphStore.setGraphNodes(nodes);
    graphStore.setGraphEdges(edges);
    graphStore.initGraphSetters({ setNodes, setEdges });
  }, [edges, graphStore, nodes, setEdges, setNodes])

  // first load graph
  useEffect(() => {
    if (selectedBoardId) {
      graphStore
        .loadGraphData(selectedBoardId)
        .then(() => {
          setNodes(graphStore.graph.nodes);
          setEdges(graphStore.graph.edges);
        });
    }

    return () => {
      setNodes([]);
      setEdges([]);
      graphStore.setIsDirty(false);
      graphStore.resetEdgeIdsToBeDeleted();
    };
  }, [selectedBoardId, graphStore, setNodes, setEdges]);

  // remind user to save
  useEffect(() => {
    var timeout: NodeJS.Timeout | null = null;
    if (graphStore.isDirty) {
      timeout = setTimeout(() => {
        if (graphStore.isDirty) {
          toast.info("Sie haben bereits einige Zeit lang nicht gespeichert.");
        }
      }, 900000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphStore.isDirty]);

  // fired when tab or browser is closed
  window.addEventListener("beforeunload", (ev) => {
    if (graphStore.isDirty) {
      ev.preventDefault();
      return (ev.returnValue = "Änderungen verwerfen?");
    }
  });

  // fired when user navigates elsewhere
  unstable_usePrompt({
    when: graphStore.isDirty,
    message: "Änderungen verwerfen?",
  }); //TODO: react router yet did not bring back a usePrompt entirely, over the time of programming check if this changes, see: https://github.com/remix-run/react-router/issues/8139

  const onDropNewNode = (event: any) => {
    event.preventDefault();

    const position = getNewNodePosition(event);

    try {
      const node = JSON.parse(
        event.dataTransfer.getData("application/reactflow/node")
      ) as NodeData;

      if (node?.id) {
        graphStore.showBoardNode({
          id: undefined!,
          nodeId: node.id,
          title: node.title,
          description: node.shortDescription,
          type: node.type,
          position: position,
          edges: node.edges,
        });
        return;
      }
    } catch (error) { }
  };

  const reactNodes = useMemo(
    () =>
      nodes.map((value) => ({
        ...value,
        deletable: !selectedBoard?.readonly,
        style: {
          borderRadius: "9px",
        },
      })),
    [nodes, selectedBoard?.readonly]
  );

  const reactEdges = useMemo(
    () =>
      edges.map((value) => ({
        ...value,
        deletable: !selectedBoard?.readonly,
        focusable: !selectedBoard?.readonly,
        ...(selectedBoard?.readonly
          ? {
            selected: false,
          }
          : {}),
      })),
    [edges, selectedBoard?.readonly]
  );

  const getNewNodePosition = (event: any) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    const position: XYPosition = reactFlowInstance?.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    return position;
  };

  const onConnect = useCallback(
    (params: Connection) => {
      const id = uuid();

      setEdges((eds) =>
        addEdge(
          {
            ...params,
            label: "?",
            id: id,
            data: {
              id: id,
              sourceNodeId: params.source,
              targetNodeId: params.target,
            },
          },
          eds
        )
      );
      graphStore.setIsDirty(true);
    },
    [setEdges, graphStore]
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      if (localStorage.getItem("didSelectOnlyOneEdge") === "true") {
        //makes sure that edges can only be deleted if they were selected individually, no deletion shall take place as a sideproduct of reactflowNode deletion, also see onSelectionChange
        if (!edgeIdsToBeDeleted.includes(edges[0].id)) {
          graphStore.addEdgeIdToBeDeleted(edges[0].id);
        }
      }
      graphStore.setIsDirty(true);
    },
    [edgeIdsToBeDeleted, graphStore]
  );

  const onNodesDelete = useCallback(
    (_: Node[]) => {
      graphStore.setIsDirty(true);
    },
    [graphStore]
  );

  const onSelectionChange = useCallback(
    ({ nodes, edges }: OnSelectionChangeParams) => {
      //deal with selection of node to display it on details section
      if (nodes.length === 1) {
        graphStore.setSelectedNode(nodes[0]);
      } else {
        graphStore.setSelectedNode(null);
      }
      //deal with edge selection to decide if it would be deleted or hidden
      if (edges.length === 1 && nodes.length === 0) {
        graphStore.setSelectedEdge(edges[0]);
        localStorage.setItem("didSelectOnlyOneEdge", "true");
      } else {
        graphStore.setSelectedEdge(null);
        localStorage.setItem("didSelectOnlyOneEdge", "false");
      }
    },
    [graphStore]
  );

  const onNodeDrag: NodeDragHandler = useCallback(
    (_, __) => {
      graphStore.setIsDirty(true);
    },
    [graphStore]
  );

  const showTools = !!selectedNode || !!selectedEdge;

  return (
    <>
      {graphStore.loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 800,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="dndflow">
          <ReactFlowProvider>
            <div
              className="reactflow-wrapper"
              ref={reactFlowWrapper}
              style={{ position: "relative" }}
            >
              <ReactFlow
                snapToGrid
                nodes={reactNodes}
                edges={reactEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDrag={onNodeDrag}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDropNewNode}
                onDragOver={onDragOver}
                fitView
                edgeTypes={reactFlowEdgeTypes}
                nodeTypes={reactFlowNodeTypes}
                onEdgesDelete={onEdgesDelete}
                onNodesDelete={onNodesDelete}
                onSelectionChange={onSelectionChange}
                deleteKeyCode={["Backspace", "Delete"]}
                // readonly specific configuration
                nodesDraggable={!selectedBoard?.readonly}
                nodesConnectable={!selectedBoard?.readonly}
              >
                <Controls showInteractive={!selectedBoard?.readonly} />
                <Background />
              </ReactFlow>

              <GraphEditorDetails
                hidden={!showTools}
              />

              <GraphEditorTools
                hidden={showTools}
              />
            </div>
          </ReactFlowProvider>
        </div>
      )}
    </>
  );
});
