import { Dialog, DialogContent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

export default observer(function ModalContainer() {
  const { modalStore } = useStore();

  return (
    <Dialog
      open={modalStore.modal.open}
      onClose={modalStore.closeModal}
      PaperProps={{
        sx: {
          background: "none",
          borderRadius: modalStore.modal.style?.borderRadius || "20px",
        },
      }}
    >
      <DialogContent
        sx={{
          background: "white",
          borderRadius: "20px",
          padding: "20px",
          ...modalStore.modal.style,
        }}
      >
        <Typography color="text.primary">{modalStore.modal.body}</Typography>
      </DialogContent>
    </Dialog>
  );
});
