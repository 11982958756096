import { Box, Paper } from '@mui/material';
import jwtDecode from "jwt-decode";
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HomePage from '../../features/home/HomePage';
import ModalContainer from '../common/modals/ModalContainer';
import UserTermsAcceptance from '../common/UserTermsAcceptance';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import NavBarMui from './NavBarMui';

// NOTE: this can be reuse in other components
function verifyTokenExpiration(token: string) {
  return Math.ceil(Date.now() / 1000) < jwtDecode<{ exp: number }>(token).exp
}

function App() {
  const location = useLocation();
  const {commonStore, accountStore, nodeStore, graphStore} = useStore();

  useEffect(() => {
    if (commonStore.token && verifyTokenExpiration(commonStore.token)) {
      nodeStore.loadNodeTypes()
      nodeStore.loadCompetenceLevels()
      graphStore.loadEdgeTypes()
      accountStore.getUser().finally(() => commonStore.setAppLoaded())
    } else {
      commonStore.setAppLoaded()
    }
    commonStore.setUiBlocked(!accountStore.didAcceptTerms);
  }, [commonStore, accountStore, nodeStore, graphStore, accountStore.didAcceptTerms])

  useEffect(() => {
    window.addEventListener('resize', commonStore.checkAndSetIsMobile)
    return () => window.removeEventListener('resize', commonStore.checkAndSetIsMobile)
  }, [commonStore.isMobileDevice, commonStore.checkAndSetIsMobile])

  if (!commonStore.appLoaded) return <LoadingComponent content='Loading app...'/>

  if (commonStore.isMobileDevice) {
    window.alert('Diese App ist für den Browser auf einem PC designed.Bitte greifen Sie von einem PC/Laptop zu.')
  }

  return (
    <>
      <ScrollRestoration/>
      <ModalContainer/>
      <ToastContainer position='bottom-right' hideProgressBar theme='colored'/>
      {location.pathname === '/' ? <HomePage/> : (
        <>
          {commonStore.uiBlocked && <UserTermsAcceptance/>}
          <Box
            display='flex'
            minHeight='100vh'
          >
            <NavBarMui/>
            <Paper sx={{flex: 'auto'}}>
              <Outlet/>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}

export default observer(App);