import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import AdminScreen from "../../features/admin/AdminScreen";
import DraftOrganizationSyncScreen from "../../features/admin/DraftOrganizationSyncScreen";
import BoardScreen from "../../features/boards/BoardsScreen";
import BoardDetailsScreen from "../../features/boards/details/BoardDetailsScreen";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import GraphEditorScreen from "../../features/graph/GraphEditorScreen";
import NodesScreen from "../../features/nodes/NodesScreen";
import NodeDetailsScreen from "../../features/nodes/details/NodeDetailsScreen";
import AddLearningGoalScreen from "../../features/nodes/details/learningGoals/AddLearningGoalScreen";
import EditLearningGoalScreen from "../../features/nodes/details/learningGoals/EditLearningGoalScreen";
import LearningGoalsScreen from "../../features/nodes/details/learningGoals/LearningGoalsScreen";
import OrganizationDetailsScreen from "../../features/organizations/OrganizationDetailsScreen";
import UserDetailsScreen from "../../features/users/UserDetailsScreen";
import App from "../layout/App";
import { FrontChannelLogout } from "../services/meinBildungsraumOAuth/FrontChannelLogout";
import RequireAuth from "./RequireAuth";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                element: <RequireAuth />, children: [
                    { path: 'boards', element: <BoardScreen /> },
                    { path: 'boards/:id', element: <BoardDetailsScreen /> },
                    { path: 'graphEditor', element: <GraphEditorScreen /> },
                    { path: 'graphEditor/:id', element: <GraphEditorScreen /> },
                    { path: 'nodes', element: <NodesScreen /> },
                    { path: 'nodes/new', element: <NodeDetailsScreen /> },
                    { path: 'nodes/:id', element: <NodeDetailsScreen /> },
                    { path: 'nodes/:id/learning-goals', element: <LearningGoalsScreen /> },
                    { path: 'nodes/:id/learning-goals/new', element: <AddLearningGoalScreen /> },
                    { path: 'nodes/:id/learning-goals/:lgsId', element: <EditLearningGoalScreen /> },
                    { path: 'administration', element: <AdminScreen /> },
                    { path: 'organizations/:id', element: <OrganizationDetailsScreen /> },
                    { path: 'createOrganization', element: <OrganizationDetailsScreen /> },
                    { path: 'draftOrgaSync', element: <DraftOrganizationSyncScreen /> },
                    { path: 'users/:id', element: <UserDetailsScreen /> },
                    { path: 'createUser', element: <UserDetailsScreen /> },
                    { path: 'errors', element: <TestErrors /> },
                    { path: 'logout', element: <FrontChannelLogout /> }
                ]
            },
            { path: 'not-found', element: <NotFound /> },
            { path: 'server-error', element: <ServerError /> },
            { path: '*', element: <Navigate replace to='/not-found' /> },
        ]
    }
]

export const router = createBrowserRouter(routes);
