import { UserManager, UserManagerSettings } from "oidc-client-ts";

const oidcConfigDev: UserManagerSettings = {
  authority: "https://aai.demo.meinbildungsraum.de/realms/nbp-aai",
  client_id: "2423a563-88da-49c7-85b1-e8bbec33f092",
  redirect_uri: "http://localhost:3000",
  post_logout_redirect_uri: "http://localhost:3000",
  response_type: "code",
  scope: "openid",
  disablePKCE: true,
  metadata: {
    authorization_endpoint:
      "https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect/auth",
    end_session_endpoint:
      "https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect/logout",
  },
};

const oidcConfigProd: UserManagerSettings = {
  authority: "https://aai.demo.meinbildungsraum.de/realms/nbp-aai",
  client_id: "bd93c961-717e-445e-a424-ac3277977830",
  redirect_uri: "https://cokomo.code4you.com",
  post_logout_redirect_uri: "https://cokomo.code4you.com",
  response_type: "code",
  scope: "openid",
  disablePKCE: true,
  metadata: {
    authorization_endpoint:
      "https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect/auth",
    end_session_endpoint:
      "https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect/logout",
  },
};

const isDevMode = process.env.NODE_ENV === "development";

const myConfig = isDevMode ? oidcConfigDev : oidcConfigProd;
const oAuthManager = new UserManager(myConfig);

export const fetchMeinBildungsraumUserCode = async () => {
  return new Promise<string>((_resolve, reject) => {
    oAuthManager
      .signinRedirect()
      .then((_result) => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userCode = params.get("code");
        if (!userCode) return reject("Code does not exist");
        return userCode;
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const oAuthLoginMeinBildungsraumUser = (
  oauthLoginFunction: Function
) => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const userCode = params.get("code");

  if (userCode && userCode.length > 10) oauthLoginFunction(userCode);
};

export const oAuthLogoutMeinBildungsraumUser = () => {
  oAuthManager.signoutRedirect();
  // const logoutUrl = `https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect/logout?client_id=${myConfig.client_id}?post_logout_redirect_uri=${myConfig.post_logout_redirect_uri}`;
  // window.location.href = logoutUrl;
  console.log("logout oauth fired");
};
