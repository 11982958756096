import { autorun, makeAutoObservable, reaction, runInAction } from "mobx";
import { v4 as uuid } from 'uuid';

import agent from "../api/agent";
import { Board } from "../models/board";

export default class BoardStore {

  readonly MAX_OPENED_BOARDS = 6;

  boards: Board[] | null = null
  templateBoards: Board[] | null = null

  selectedBoard: Board | null = null

  loading = false;
  editMode = false;
  openedBoards: Board[] = [];

  constructor() {
    makeAutoObservable(this)

    // auto load openedBoards from storage
    autorun(() => {
      const storedJson = localStorage.getItem("openedBoards")
      if (storedJson) {
        try {
          this.openedBoards = JSON.parse(storedJson) ?? []
        } catch (error) {
          this.clearOpenedBoards();
        }
      }
    })

    // auto save openedBoards to storage
    reaction(
      () => this.openedBoards,
      (openedBoards) => {
        localStorage.setItem("openedBoards", JSON.stringify(openedBoards))
      }
    )
  }

  loadBoards = async () => {
    this.setLoading(true)
    this.boards = null

    try {
      const boards = await agent.Boards.list()
      runInAction(async () => {
        this.boards = boards;
      })
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false)

    this.cleanOpenedBoards(false)
  }

  loadTemplateBoards = async () => {
    this.setLoading(true)
    this.templateBoards = null

    try {
      const templateBoards = await agent.Boards.listTemplate()
      runInAction(async () => {
        this.templateBoards = templateBoards;
      })
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false)
  }

  loadBoard = async (id: string) => {
    this.setLoading(true)
    try {
      const board = await agent.Boards.get(id)
      if (board) {
        runInAction(() => {
          this.setSelectedBoard(board)
        })
      }
    } catch (error) {
      console.error(error)
    }
    this.setLoading(false)
  }

  update = async (board: Board) => {
    await agent.Boards.update(board)
    runInAction(async () => {
      this.setSelectedBoard(board)
    })
  }

  create = async (board: Board) => {
    await agent.Boards.create(board)
    runInAction(async () => {
      this.setSelectedBoard(board)
    })
  }

  initiateNewBoard = () => {
    const newBoard = new Board(
      {
        id: uuid(),
        title: '',
        description: '',
        isInactive: false,
        modifiedOn: new Date(Date.now()),
        createdOn: new Date(Date.now()),
        modifiedBy: undefined,
        createdBy: undefined,
      }
    )
    return newBoard
  }

  deleteBoard = async (boardId: string) => {
    await agent.Boards.delete(boardId)
    this.boards = this.boards?.filter((b) => b.id !== boardId) || null
    this.clearSelectedBoard()

    this.cleanOpenedBoards();
  }

  setLoading = (state: boolean) => {
    this.loading = state;
  }

  setSelectedBoard = (board: Board) => {
    if (board) {
      this.selectedBoard = board
      this.addOpenedBoard(board)
    } else {
      this.selectedBoard = null
    }
  }

  clearSelectedBoard = () => {
    this.selectedBoard = null
  }

  addOpenedBoard = (board: Board) => {
    this.remOpenedBoard(board.id)
    this.openedBoards.unshift(board)

    if (this.openedBoards.length > this.MAX_OPENED_BOARDS) {
      this.openedBoards.pop()
    }
  }

  remOpenedBoard = (boardId: string) => {
    this.openedBoards = this.openedBoards.filter(b => b.id !== boardId)
  }

  clearOpenedBoards = () => {
    this.openedBoards = []
  }

  cleanOpenedBoards = async (refetch: boolean = true) => {
    if (refetch) {
      await this.loadBoards();
    }

    runInAction(() => {
      const boardIds = this.boards?.map(b => b.id) ?? [];
      this.openedBoards = this.openedBoards.filter(b => boardIds.indexOf(b.id) >= 0)
    })
  }

  get selectedBoardId() {
    if (this.selectedBoard) {
      return this.selectedBoard?.id
    } else return ''
  }

}
