import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";

import { Box, Grid, IconButton, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AddButton from "../../app/common/form/AddButton";

import { router } from "../../app/router/Routes";
import { Link } from "react-router-dom";

import { ListWithBorders } from "../../app/common/ListWithBorders";

export default observer(function AdminScreen() {

    const { orgaStore: oS, userManagerStore: uS } = useStore();

    useEffect(() => {
        if (oS.registry.length === 0) oS.loadItemsPagedFromServer()
    }, [oS.registry.length]);

    useEffect(() => {
        if (oS.selectedItem?.id) uS.loadByOrganizationId(oS.selectedItem!.id);
        else {
            uS.clearRegistry()
            uS.clearSelectedItem()
        }
    }, [uS, oS, oS.selectedItem]);

    return (
        <Box height={'100%'}>
            <Grid container spacing={2} >
                <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={2} >
                        <Typography variant="h4" color={'primary'}>Admin</Typography>
                    </Grid>
                    <Grid item container xs={1} justifyContent={'flex-end'}>
                        <AddButton onClick={() => router.navigate('/createOrganization')} />
                    </Grid>
                    <Grid item container xs={5} justifyContent={'flex-end'}>
                        {oS.selectedItem && <AddButton onClick={() => router.navigate('/createUser')} />}
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <ListWithBorders height={'calc(100vh - 220px)'}>
                        {oS.itemsByCreatedDate.map(org => {
                            return <ListItemButton
                                onClick={() => oS.setSelectedItem(org)}
                                selected={oS.selectedItem && oS.selectedItem.id === org.id}
                                key={`li_${org.id}`}>
                                <ListItemText primary={org.title} />
                                <IconButton component={Link} to={`/organizations/${org.id}`}>
                                    <Edit></Edit>
                                </IconButton>
                            </ListItemButton>
                        })
                        }
                    </ListWithBorders>
                </Grid>
                <Grid item xs={5}>
                    <ListWithBorders height={'calc(100vh - 220px)'}>
                        {uS.itemsByCreatedDate.map(u => {
                            return <ListItemButton
                                onClick={() => uS.setSelectedItem(u)}
                                selected={uS.selectedItem && uS.selectedItem.id === u.id}
                                key={`li_${u.id}`}>
                                <ListItemText primary={u.email} secondary={u.displayName} />
                                <IconButton component={Link} to={`/users/${u.id}`}>
                                    <Edit></Edit>
                                </IconButton>
                            </ListItemButton>
                        })}
                    </ListWithBorders>
                </Grid>
            </Grid>
        </Box >
    );
})