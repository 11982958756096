import { IBaseEntity, IBaseEntityEdit } from "./base";
import { Organization } from "./organization";

export interface User {
    id: string,
    displayName: string,
    username: string,
    image?: string,
    bio: string,
    email: string,
    organization: Organization,
    roleNames: string,
    token: string,
    acceptedTermsOn: Date,
}

export interface UserFormValues {
    email: string;
    password: string;
    displayName?: string;
    username?: string;
}

export interface UserDto extends IBaseEntity {
    id: string,
    displayName: string,
    username: string,
    image?: string,
    bio: string,
    email: string,
    organization: Organization,
    roleNames: string,
    token: string;
    password?: string;
    acceptedTermsOn: Date,
}

export interface UserFormValuesDto extends IBaseEntityEdit {
    id?: string,
    email: string;
    defaultPassword?: string;
    displayName: string;
    username?: string;
    bio?: string,
    organizationId?: string,
}

export class UserFormValuesDto implements UserFormValuesDto {
    id?: string = undefined;
    email: string = '';
    defaultPassword?: string = '';
    displayName: string = '';
    username?: string;
    bio?: string;
    organizationId?: string;

    constructor(item?: UserFormValuesDto) {
        if (item) {
            this.id = item.id;
            this.email = item.email;
            this.defaultPassword = item.defaultPassword;
            this.displayName = item.displayName;
            this.username = item.username;
            this.bio = item.bio;
            this.organizationId = item.organizationId;
        }
    }
}


