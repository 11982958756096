import { Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';


interface Params {
    title: string,
    text: string,
    onConfirm: Function,
    onCancel: Function
}

export default function InputModal({ title, text, onConfirm, onCancel }: Params) {

    const [val, setVal] = useState('');

    const handleConfirm = () => {
        onConfirm(val)
        setVal('')
    }

    return (
        <>
            <Typography gutterBottom variant="h5" component="div">
                {title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {text}
            </Typography>
            <TextField fullWidth onChange={(e) => { setVal(e.target.value) }}>{val}</TextField>
            <Stack mt={2} direction='row' justifyContent={'flex-end'} spacing={2}>
                <Button onClick={() => onCancel()} size="small">Abbrechen</Button>
                <Button variant='outlined' onClick={handleConfirm} size="small">Bestätigen</Button>
            </Stack>
        </>
    );
}