import { Button } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import { unstable_usePrompt } from "react-router-dom"

interface Props {
    text: string

}
const SubmitButtonMuiFormik = ({ text }: Props) => {
    const { submitForm, dirty, isValid } = useFormikContext()
    const handleSubmit = () => {
        submitForm()
    }

    unstable_usePrompt({ when: dirty, message: 'Änderungen verwerfen?' });//TODO: react router yet did not bring back a usePrompt entirely, over the time of programming check if this changes, see: https://github.com/remix-run/react-router/issues/8139 

    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
        if (dirty) {
            ev.preventDefault();
            ev.returnValue = "Änderungen verwerfen?";
        }
    };

    useEffect(() => {
        if (dirty) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        } else {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
        // eslint-disable-next-line
    }, [dirty]);

    return (
        <Button disabled={!dirty || !isValid} variant='outlined' onClick={handleSubmit}>
            {text}
        </Button>
    )
}

export default SubmitButtonMuiFormik