import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import { User } from "../models/user";

export default class UserStore {
    userRegistry = new Map<string, User>();
    selectedUser?: User = undefined;
    loading = false;
    loadingInitial = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('searchTerm', '');

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.userRegistry.clear();
                this.loadUsersPaged();
            }
        )
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (_predicate: string, value: string) => {
        const resetPredicate = () => {
            this.predicate.forEach((_, key) => {
                this.predicate.delete(key);
            })
        }
        resetPredicate();
        this.predicate.set('searchTerm', value);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString())
        this.predicate.forEach((value, key) => { params.append(key, value) })

        return params;
    }

    loadUsersForOrga = async (orgId: string) => {
        this.loading = true
        try {
            const respUsers = await agent.Organizations.listUsers(orgId)
            this.userRegistry.clear()
            respUsers.forEach(u => {
                this.setUser(u);
            })
            this.loading = false;
        } catch (error) {
            console.error(error)
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    get users() {
        return Array.from(this.userRegistry.values())
    }

    loadUsersPaged = async (searchEmail?: string) => {
        this.loadingInitial = true;
        try {
            if (searchEmail) this.setPredicate('searchTerm', searchEmail);
            const result = await agent.Users.listPaged(this.axiosParams);
            result.data.forEach(u => {
                this.setUser(u);
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    private setUser = (user: User) => {
        this.userRegistry.set(user.id, user);
    }

    private getUser = (id: string) => {
        return this.userRegistry.get(id);
    }

    setSelectedUser = (user: User) => {
        this.selectedUser = user
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    clearSelectedOrga = () => {
        this.selectedUser = undefined;
    }
}