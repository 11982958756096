import { Add, ArrowDownwardOutlined, ArrowUpwardOutlined, DateRangeOutlined, FilterAltOutlined, SortOutlined, TitleOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MultiChoicesMenu, MultiChoicesStore } from "../../app/common/MultiChoicesMenu";
import SearchInput from "../../app/common/SearchInput";
import { useDebounce } from "../../app/common/UtilHooks";
import { useStore } from "../../app/stores/store";
import BoardCard from "./BoardCard";
import BoardDetailsForm from "./details/BoardDetailsForm";


class FilterStore extends MultiChoicesStore {
  constructor() {
    super([
      [
        {
          content: "Eigene Inhalte",
        },
        {
          content: "Schreibgeschützt",
        },
      ]
    ])

    makeObservable(this, {
      visibility: computed,
    })
  }

  public get visibility() {
    return this.getSectionValue(0);
  }
}


class SortStore extends MultiChoicesStore {
  constructor() {
    super([
      [
        {
          icon: <TitleOutlined />,
          content: "Name",
          isDefault: true,
        },
        {
          icon: <DateRangeOutlined />,
          content: "Erstellungsdatum",
        },
        {
          icon: <DateRangeOutlined />,
          content: "Letzte Bearbeitung",
        },
      ],
      undefined,
      [
        {
          icon: <ArrowUpwardOutlined />,
          content: "Aufsteigend",
          isDefault: true,
        },
        {
          icon: <ArrowDownwardOutlined />,
          content: "Absteigend",
        },
      ]
    ])

    makeObservable(this, {
      field: computed,
      direction: computed,
    })
  }

  public get field() {
    return this.getSectionValue(0);
  }

  public get direction() {
    return this.getSectionValue(1);
  }
}


export default observer(function BoardScreen() {
  const { boardStore, modalStore } = useStore();
  const { boards, templateBoards, loadBoards, loadTemplateBoards, loading } =
    boardStore;

  const [sort] = useState(() => new SortStore());
  const [filter] = useState(() => new FilterStore());

  const [searchString, setSearchString] = useState("");
  const debouncedSearchString = useDebounce(searchString.trim(), 500)
    .toUpperCase()
    .trim();

  useEffect(() => {
    loadBoards();
    loadTemplateBoards();
  }, [loadBoards, loadTemplateBoards]);

  const allBoards = useMemo(() => {
    let result = boards?.concat(templateBoards || []) ?? [];

    if (debouncedSearchString.length > 0) {
      result = result.filter(
        (board) => board.title.toUpperCase().indexOf(debouncedSearchString) > -1
      );
    }

    // FILTER
    const filterByVisibility = filter.visibility;

    switch (filterByVisibility) {
      case 0:
        // In Orga
        result = result.filter(e => !e.readonly);
        break;
      case 1:
        // Read-only
        result = result.filter(e => e.readonly);
        break;
      default:
        break;
    }

    // SORT
    const sortByField = sort.field;
    const sortDirection = sort.direction;

    switch (sortByField) {
      case 0:
        // Name
        result = result.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 1:
        // Created At
        result = result.sort(
          (a, b) =>
            new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        );
        break;
      case 2:
        // Updated At
        result = result.sort(
          (a, b) =>
            new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime()
        );
        break;
    }

    if (sortDirection === 1) {
      result = result.reverse();
    }

    return result;
  }, [boards, templateBoards, debouncedSearchString, filter.visibility, sort.field, sort.direction]);

  return (
    <div>
      <Box sx={{ overflowY: "auto" }} height={"100%"} paddingX={10}>
        <Grid container alignContent="flex-start">
          <Grid item xs={12} paddingY={4} paddingX={2}>
            <Typography variant="h4">Meine Boards</Typography>
          </Grid>

          <Grid container item xs={12} padding={2}>
            <Grid item xs={12} lg={4}>
              <Typography variant="h6">
                Insgesamt {allBoards.length} Boards
              </Typography>
            </Grid>

            <Grid container item spacing={1} xs={12} lg={8} justifyContent="end">
              <Grid item>
                <SearchInput
                  placeholder="Suche Board"
                  value={searchString}
                  setValue={setSearchString}
                />
              </Grid>

              <Grid item>
                <MultiChoicesMenu
                  icon={<SortOutlined />}
                  title="Sortierung"
                  value={sort.value}
                  onValueChange={sort.setValue.bind(sort)}
                />
              </Grid>

              <Grid item>
                <MultiChoicesMenu
                  icon={<FilterAltOutlined />}
                  title="Filter"
                  value={filter.value}
                  onValueChange={filter.setValue.bind(filter)}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "999px",
                    height: "100%",
                  }}
                  startIcon={<Add />}
                  onClick={() =>
                    modalStore.openModal(<CreateNewBoardModal />, "small")
                  }
                >
                  <Typography fontWeight="600">
                    Board erstellen
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container paddingY={2} spacing={2}>
            {loading ? (
              <Grid item display="flex" justifyContent="center" xs={12}>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {allBoards.map((board) => (
                  <Grid
                    item
                    key={`gridItem_${board.id}`}
                    height="200px"
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                  >
                    <BoardCard board={board} />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});

function CreateNewBoardModal(props: {}) {
  const { boardStore, modalStore } = useStore();
  const { clearSelectedBoard } = boardStore;

  const [isNew, setIsNew] = useState(true);

  const hook_setIsNew = useCallback(
    (value: boolean) => {
      if (!value) {
        boardStore.loadBoards().finally(() => {
          modalStore.closeModal();
        });
      }

      setIsNew(value);
    },
    [boardStore, modalStore]
  ) as React.Dispatch<React.SetStateAction<boolean>>;

  useEffect(() => {
    clearSelectedBoard();
  }, [clearSelectedBoard]);

  return (
    <Grid container spacing={2} padding={4}>
      <Grid container item xs={12} alignItems={"center"}>
        <Grid item xs={10}>
          <Typography variant="h4">Create new board</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" color={"primary.light"}>
            Board
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BoardDetailsForm
            isNew={isNew}
            setIsNew={hook_setIsNew}
            getHeader={() => ""}
            setHeader={() => { }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
