import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography
} from "@mui/material";

import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Edge, Node, useReactFlow } from "reactflow";
import { EdgeTypeIcon } from "../../app/common/Icons";
import { EdgeData } from "../../app/models/edgeData";
import { GraphNodeData } from "../../app/models/graphData";
import { router } from "../../app/router/Routes";
import { useStore } from "../../app/stores/store";
import LabelIcon, { NodeLabelIconData } from "../nodes/NodeCardLabelIcon";

export default observer(function GraphEditorDetails({
  hidden,
}: {
  hidden?: boolean;
}) {
  const { graphStore } = useStore();
  const { selectedNode, selectedEdge } = graphStore;

  return (
    (selectedNode || selectedEdge) && (
      <Box
        display={hidden ? "none" : "block"}
        position="absolute"
        top={20}
        right={20}
        minWidth={400}
        maxWidth={500}
        width="30%"
      >
        <Card
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "20px",
            border: "1px solid #D0D0D0",
            bgcolor: "white"
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              padding: "0 !important",
              overflow: "auto",
              maxHeight: "90vh",
            }}
          >
            <Box padding={2}>
              {selectedNode && <NodeDetails node={selectedNode.data} />}
              {selectedEdge && (
                <EdgeDetails
                  edge={selectedEdge}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  );
});

const EdgeDetails = observer(
  ({
    edge,
  }: {
    edge: Edge<EdgeData>;
  }) => {
    const {
      graphStore,
      boardStore: { selectedBoard },
    } = useStore();

    return (
      <Box padding={2}>
        <Typography variant="h6" color="#49454F" noWrap>
          Verbindungstyp
        </Typography>

        <Box py={2}>
          {graphStore.edgeTypes.map((edgeType) => {
            const isSelected = edgeType.abbreviation === edge.label;
            const color = isSelected ? "#404040" : "#A0A0A0";
            const fontWeight = isSelected ? 600 : 400;

            return (
              <Button
                key={`edge_type_${edgeType.id}`}
                fullWidth
                sx={{ justifyContent: "start" }}
                size="large"
                startIcon={
                  <EdgeTypeIcon variant={edgeType.abbreviation} color={color} />
                }
                onClick={() => graphStore.changeEdgeType(edge.id, edgeType.abbreviation)}
              >
                <Typography color={color} fontWeight={fontWeight}>
                  {edgeType.title}
                </Typography>
              </Button>
            );
          })}
        </Box>

        <Box pt={2} display="flex" justifyContent="end">
          <Button
            color="error"
            variant="text"
            disableRipple
            disabled={selectedBoard?.readonly}
            onClick={() => graphStore.removeEdge(edge.id)}
            startIcon={<DeleteForeverOutlined />}
            sx={{ p: 1 }}
          >
            <Typography
              fontWeight={600}
              color={selectedBoard?.readonly ? "inherit" : "error"}
            >
              Verbindung löschen
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }
);

const NodeDetails = observer(({ node }: { node: GraphNodeData }) => {
  const reactflow = useReactFlow<GraphNodeData, EdgeData>();
  const nodes = reactflow.getNodes();
  const edges = reactflow.getEdges();

  const extendEdge = (edge: Edge<EdgeData>) => {
    if (!edge.targetNode) {
      edge.targetNode = nodes.find(({ id }) => edge.target === id);
    }

    if (!edge.sourceNode) {
      edge.sourceNode = nodes.find(({ id }) => edge.source === id);
    }

    return edge;
  }

  return (
    <>
      <Box padding={2}>

        <Box
          display="flex"
          alignItems="center"
        >
          <Box paddingX={1}>
            <LabelIcon
              color={NodeLabelIconData[node.type].primary}
            />
          </Box>

          <Typography variant="h5" noWrap>
            {node.title}
          </Typography>
        </Box>

        <Box paddingY={3}>
          <Typography color="GrayText">{node.description}</Typography>
        </Box>

        <Box
          paddingY={2}
          display="flex"
          justifyContent="end"
        >
          <Button
            startIcon={<EditOutlined />}
            onClick={() => router.navigate(`/nodes/${node.nodeId}`)}
          >
            <Typography fontWeight={500}>Detail öffnen</Typography>
          </Button>
        </Box>

        <Divider />

        <Typography
          paddingY={3}
          color="#49454F"
        >
          Eingehende Verbindungen
        </Typography>

        <Box paddingBottom={3}>
          {
            edges
              .filter(({ target }) => node.nodeId === target)
              .map(extendEdge)
              .map(({ sourceNode, id }) => sourceNode && <EdgeNode node={sourceNode} key={`from_${id}`} />)
          }
        </Box>

        <Divider />

        <Typography
          paddingY={3}
          color="#49454F"
        >
          Ausgehende Verbindungen
        </Typography>

        <Box paddingBottom={1}>
          {edges
            .filter(({ source }) => node.nodeId === source)
            .map(extendEdge)
            .map(({ targetNode, id }) => targetNode && <EdgeNode node={targetNode} key={`to_${id}`} />)
          }
        </Box>

      </Box>
    </>
  );
});

function EdgeNode({ node }: { node: Node<GraphNodeData> }) {
  const reactflow = useReactFlow();

  const fitToNode = () => {
    reactflow.fitView({
      nodes: [node],
      duration: 500,
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={fitToNode}
      paddingY={0.2}
    >
      <Box paddingX={1}>
        <LabelIcon
          color={NodeLabelIconData[node.data.type].secondary}
        />
      </Box>

      <Typography
        fontWeight={500}
        color="#49454F"
        noWrap
      >
        {node.data.title}
      </Typography>
    </Box>
  );
}
