import { IBaseEntity, IBaseEntityEdit } from "./base";

export interface Organization extends IBaseEntity {
    id: string,
    title: string,
    description: string,
    createdOn: Date,
    isInactive: boolean
    createdBy: string,
    modifiedOn: Date,
    modifiedBy: string,
}

export interface OrganizationFormValues extends IBaseEntityEdit {
    id?: string;
    title: string;
    description: string;
}

export class OrganizationFormValues implements OrganizationFormValues {
    id?: string = undefined;
    title: string = '';
    description: string = '';

    constructor(orga?: OrganizationFormValues) {
        if(orga) {
            this.id = orga.id;
            this.title = orga.title;
            this.description = orga.description
        }
    }
}

