import { reaction, runInAction, makeObservable, observable } from "mobx";
import { Pagination, PagingParams } from "../models/pagination";
import { IBaseEntity, IBaseEntityEdit } from "../models/base";
import { BaseAgent } from "../api/common";

export class BaseStore<TClass extends IBaseEntity, TClassEdit extends IBaseEntityEdit> {

    agent: BaseAgent<TClass, TClassEdit>;
    registry: TClass[] = new Array<TClass>();
    selectedItem?: TClass = undefined;
    loading = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('searchTerm', '');

    constructor(agent: BaseAgent<TClass, TClassEdit>) {
        this.agent = agent;

        makeObservable(this, {
            registry: observable,
            selectedItem: observable,
            loading: observable,
            pagination: observable,
            pagingParams: observable,
            predicate: observable,
        })

        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.clearRegistry();
                this.loadItemsPagedFromServer();
            }
        )
    }

    clearRegistry = () => {
        this.registry = new Array<TClass>();
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString())
        this.predicate.forEach((value, key) => { params.append(key, value) })

        return params;
    }

    setItem = (item: TClass) => {
        item.createdOn = new Date(item.createdOn);
        item.modifiedOn = new Date(item.modifiedOn);
        const ind = this.registry.findIndex(o => o.id === item.id)
        if (ind === -1) {
            this.registry.push(item);
        } else {
            this.registry[ind] = item;
        }
    }

    setSelectedItem = (item: TClass) => {
        this.selectedItem = item;
    }

    clearSelectedItem = () => {
        this.selectedItem = undefined;
    }

    loadItemsPagedFromServer = async () => {
        this.loading = true;
        this.clearRegistry();
        try {
            const result = await this.agent.listPaged(this.axiosParams);
            result.data.forEach(item => {
                this.setItem(item);
            });
            this.loading = false;
            this.pagination = result.pagination;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }

    loadItemFromServer = async (id: string) => {
        this.loading = true;
        try {
            var itemServer = await this.agent.get(id);
            this.setItem(itemServer);
            this.setSelectedItem(itemServer);
            this.loading = false;
            return itemServer;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }

    getItemFromRegistry = (id: string) => {
        return this.registry.find(o => o.id === id)
    }

    get itemsByCreatedDate() {
        return [...this.registry].sort((a, b) =>
            a.createdOn.getTime() - b.createdOn.getTime());
    }

    updateItem = async (id: string, item: TClassEdit) => {
        try {
            const updatedItem = await this.agent.update(id, item);
            runInAction(() => {
                if (updatedItem.id) {
                    this.setItem(updatedItem);
                    this.selectedItem = updatedItem;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    createItem = async (item: TClassEdit) => {
        try {
            const newItem = await this.agent.create(item)
            this.setItem(newItem)
            this.setSelectedItem(newItem)
        } catch (error) {
            console.error(error)
        }
    }

    deleteItem = async (id: string) => {
        try {
            const success = await this.agent.delete(id)
            if (success) {
                this.clearSelectedItem()
                this.registry = this.registry.filter(item => item.id !== id);
            }
        } catch (error) {
            console.error(error)
        }
    }
}