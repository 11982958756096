import { NodeType } from "../../app/models/nodeData";

export const NodeLabelIconData: {
  [type: NodeType | string]: {
    primary: string;
    secondary: string;
    text: string,
  };
} = {
  [NodeType.Principle]: {
    primary: "#0072C3",
    secondary: "#B6D6ED",
    text: "Prinzip",
  },
  [NodeType.Concept]: {
    primary: "#D02670",
    secondary: "#FFD6E8",
    text: "Konzept",
  },
  [NodeType.Procedure]: {
    primary: "#007D79",
    secondary: "#B2E8E4",
    text: "Prozess",
  },
};

function LabelIcon(props: { color: string, size?: number }) {
  const size = props.size ?? 12;

  return (
    <>
      <div
        style={{
          width: size,
          height: size,
          borderRadius: 1000,
          background: props.color,
          marginRight: 10,
        }}
      />
    </>
  );
}

export default LabelIcon;
