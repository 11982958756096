import { TextField, TextFieldProps } from "@mui/material"
import { useField } from "formik"

interface Props {
    name: string
    label?: string
    multiline?: boolean
    rows?: number
    size?: "small" | "medium"
    disabled?: boolean
    type?: string
}

const TextInputMuiFormik=({name, ...otherprops}: Props)=>{

    const [field, meta]=useField(name)

    if(!field.value) field.value=''

    const configTextField: TextFieldProps={
        ...field,
        ...otherprops,
        variant:'outlined', 
        fullWidth: true,
        sx: {my: 1}
    }

    if(meta && meta.touched && meta.error){
        configTextField.error=true
        configTextField.helperText=meta.error
    }

    return (
        <TextField {...configTextField}></TextField>
    )
}

export default TextInputMuiFormik