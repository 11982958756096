import agent from "../api/agent";
import { Organization, OrganizationFormValues } from "../models/organization";
import { BaseStore } from "./baseStore";

export default class OrganizationStore extends BaseStore<Organization, OrganizationFormValues> {
    constructor() {
        super(agent.Organizations);
    }

    updateFinalDraftOrganization = async () => {
        await agent.Organizations.updateFinalDraftOrganization()
    }
}