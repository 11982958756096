import { AddOutlined, DeleteForeverOutlined, EditOutlined, FilterAltOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { MultiChoicesMenu, MultiChoicesStore } from '../../../../app/common/MultiChoicesMenu';
import SecondaryScreenWrapper from '../../../../app/common/SecondaryScreenWrapper';
import { LearningGoal } from '../../../../app/models/learningGoal';
import { router } from '../../../../app/router/Routes';
import { useStore } from '../../../../app/stores/store';


class FilterStore extends MultiChoicesStore {
  constructor(learningGoals: LearningGoal[]) {
    super([
      learningGoals.map(({ competenceLevel: { number, title } }) => ({
        content: `${number} - ${title}`
      }))
    ])

    makeObservable(this, {
      filteredByLearningGoal: computed,
    })
  }

  public get filteredByLearningGoal() {
    return this.getSectionValue(0);
  }
}


export default observer(function LearningGoalsScreen() {
  const { id } = useParams();
  const { nodeStore } = useStore();
  const { loadNode, selectedNode, loading, deleteLearningGoalStatement } = nodeStore;

  const filter = useMemo(() =>
    new FilterStore(selectedNode?.learningGoals ?? []),
    [selectedNode]
  )

  useEffect(() => {
    if (!id) return;
    loadNode(id);
  }, [id, loadNode]);

  const learningGoalStatements = useMemo(() => {
    let result = selectedNode?.learningGoals.flatMap(lg =>
      lg.learningGoalStatements.map(lgs => ({
        ...lgs,
        learningGoal: lg,
      }))
    ) ?? [];

    const filterByLearningGoal = filter.filteredByLearningGoal;

    if (filterByLearningGoal >= 0) {
      result = result.filter(lgs => lgs.learningGoal.competenceLevel.number === filterByLearningGoal);
    }

    return result;
  }, [selectedNode?.learningGoals, filter.filteredByLearningGoal]);

  const handleEdit = (statementId: string) => {
    router.navigate(`nodes/${id}/learning-goals/${statementId}`);
  };

  const handleDelete = (statementId: string) => {
    // TODO: Use prompt
    deleteLearningGoalStatement(statementId).then(() => loadNode(id!));
  };

  return (
    <SecondaryScreenWrapper>
      <Grid container alignContent="flex-start" py={2} px={10}>
        <Grid item xs={12} py={4}>
          <Typography variant="h4">Lernzielsätze</Typography>
        </Grid>

        <Grid container item xs={12} py={2}>
          <Grid item xs={4} flexDirection="column">
            <Typography fontSize={16} fontWeight={400}>
              Insgesamt {learningGoalStatements?.length || 0} Lernziele
            </Typography>
          </Grid>

          <Grid container item xs={8} justifyContent="end" alignItems="center">
            <Grid item>
              <MultiChoicesMenu
                icon={<FilterAltOutlined />}
                title="Filter"
                value={filter.value}
                onValueChange={filter.setValue.bind(filter)}
              />
            </Grid>

            <Divider
              orientation='vertical'
              sx={{ mx: 2 }}
            />

            <Grid item>
              <Button startIcon={<AddOutlined />} variant='contained'>
                Lernzielsatz hinzufügen
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container py={4} spacing={2} xs={12}>
          {loading ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={12}
              height="calc(100vh - 300px)"
              minHeight="200px"
              borderRadius="20px"
              bgcolor="#F9F9F9"
            >
              <CircularProgress />
              <Typography variant="h5" pl={2}>
                Searching...
              </Typography>
            </Grid>
          ) : learningGoalStatements.length === 0 ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              sx={{
                borderStyle: "dashed",
                borderWidth: 1,
                borderRadius: 20,
              }}
            >
              <Typography variant="h5" pb={2}>
                Keine Kompetenzpunkte
              </Typography>
            </Grid>
          ) : (
            <>
              {learningGoalStatements?.map((lgs) => (
                <Grid item key={`gridItem_${lgs.id}`} height="120px" xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={16} fontWeight={500}>
                      {lgs.learningGoal.competenceLevel.number} - {lgs.learningGoal.competenceLevel.title}
                    </Typography>

                    <Stack direction="row" alignContent="center" gap={2}>
                      <Button
                        onClick={() => handleDelete(lgs.id)}
                        startIcon={<DeleteForeverOutlined />}
                        color="error"
                      >
                        Löschen
                      </Button>

                      <Button
                        onClick={() => handleEdit(lgs.id)}
                        startIcon={<EditOutlined />}
                      >
                        Bearbeiten
                      </Button>
                    </Stack>
                  </Stack>

                  <Box bgcolor="#F9F9F9" borderRadius={2} p={2}>
                    <Typography fontSize={14}>
                      {lgs.content}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </SecondaryScreenWrapper>
  );
})
