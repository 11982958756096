import { Search } from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";
import React from "react";

export default function SearchInput({
  placeholder,
  value,
  setValue,
}: {
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <>
      <Box
        sx={{
          border: "1px #D0D0D0 solid",
          borderRadius: "10px",
        }}
        paddingLeft={1}
        paddingRight={2}
      >
        <IconButton disableRipple sx={{ pointerEvents: "none" }}>
          <Search />
        </IconButton>

        <InputBase
          sx={{
            fontWeight: "600",
          }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
    </>
  );
}
