import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import * as Yup from 'yup';

import { useStore } from "../../app/stores/store";

import { OrganizationFormValues } from "../../app/models/organization";
import { Form, Formik } from "formik";
import { Grid, Typography } from "@mui/material";
import TextInputMuiFormik from "../../app/common/form/muiFormikComponents/TextInputMuiFormik";
import SubmitButtonMuiFormik from "../../app/common/form/muiFormikComponents/SubmitButtonMuiFormik";
import { router } from "../../app/router/Routes";
import { DeleteButtonMuiFormik } from "../../app/common/form/muiFormikComponents/DeleteButtonMuiFormik";
import { Apartment } from "@mui/icons-material";

export default observer(function OrganizationDetailsScreen() {

    const { orgaStore: oS } = useStore();

    const { id } = useParams()

    const [orga, setOrga] = useState<OrganizationFormValues>(new OrganizationFormValues());

    useEffect(() => {
        id && oS.loadItemFromServer(id).then(orga => setOrga(new OrganizationFormValues(orga)))
    }, [id, oS]);

    const validationSchema = Yup.object({
        title: Yup.string().required('Bitte einen Titel vergeben'),
    })

    async function handleFormSubmit(orga: OrganizationFormValues, { resetForm }: any) {
        try {
            if (id) {
                await oS.updateItem(id, orga)
                    .then(resetForm({ values: orga, dirty: false }))
            } else {
                await oS.createItem(orga)
                    .then(resetForm({ values: orga, dirty: false }))
            }
            toast.success('Gespeichert')
            router.navigate('/administration')
        } catch (error) {
            console.error(error)
            toast.error('Etwas lief schief: ' + error)
        }
    }

    async function handleDelete(id: string) {
        oS.deleteItem(id)
        router.navigate('/administration')
    }

    return (
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Apartment sx={{ fontSize: '2.2rem', color: 'primary.light' }} />
                </Grid>
                <Grid item>
                    <Typography variant="h4" color="primary">
                        Organisation Details
                    </Typography>
                </Grid>
            </Grid>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={orga}
                onSubmit={handleFormSubmit}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid container item justifyContent='stretch'>
                            <TextInputMuiFormik name='title' label="Titel" />
                        </Grid>
                        <Grid container item justifyContent='stretch'>
                            <TextInputMuiFormik name='description' multiline rows={4} label="Beschreibung" />
                        </Grid>
                        <Grid item container justifyContent='flex-end' spacing={2}>
                            {id && <Grid item><DeleteButtonMuiFormik onClick={() => handleDelete(id)} /></Grid>}
                            <Grid item><SubmitButtonMuiFormik text="Speichern" /></Grid>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    );
})