import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Params {
    text: string
    route: string
}

function NavigationButton({ text, route }: Params) {
    return (
        <Button endIcon={<KeyboardDoubleArrowRight />} component={Link} to={route}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <Typography >{text}</Typography>
            </Box>
        </Button>);
}

export default NavigationButton;