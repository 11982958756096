import { reaction } from "mobx";
import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import BoardStore from "./boardStore";
import CommonStore from "./commonStore";
import GraphStore from "./graphStore";
import ModalStore from "./modalStore";
import NodeStore from "./nodeStore";
import OrganizationStore from "./organizationStore";
import TranslationStore from "./translationStore";
import UserManagerStore from "./userManagerStore";
import UserStore from "./userStore";

class Store {
    commonStore: CommonStore = new CommonStore();
    accountStore: AccountStore = new AccountStore();
    modalStore: ModalStore = new ModalStore();
    graphStore: GraphStore = new GraphStore();
    nodeStore: NodeStore = new NodeStore();
    boardStore: BoardStore = new BoardStore();
    orgaStore: OrganizationStore = new OrganizationStore();
    userStore: UserStore = new UserStore();
    userManagerStore: UserManagerStore = new UserManagerStore();
    translationStore: TranslationStore = new TranslationStore();

    constructor() {

        // auto remove openedBoards when logout
        reaction(
            () => this.commonStore.token,
            (token) => {
                if (!token) {
                    this.boardStore.clearOpenedBoards();
                }
            }
        );
    }
}

export const store: Store = new Store();

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
