export interface Board {
    id: string,
    title: string,
    description: string,
    isInactive: boolean,
    readonly?: boolean,
    createdOn: Date,
    createdBy?: string,
    modifiedOn: Date,
    modifiedBy?: string,
}

export class Board implements Board {
    constructor(init?: Board) {
        Object.assign(this, init);
    }
}