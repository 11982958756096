import { Lock } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { NodeData } from "../../app/models/nodeData";
import { router } from "../../app/router/Routes";
import { useStore } from "../../app/stores/store";
import LabelIcon, { NodeLabelIconData } from "./NodeCardLabelIcon";
import RelavantBoards from "./RelavantBoards";

interface Params {
  node: NodeData;
}

function NodeCard({ node }: Params) {
  const { accountStore } = useStore();
  const { user } = accountStore;

  const readonly = user?.organization.id !== node?.organizationId;

  const navigateToNode = () => {
    router.navigate(`/nodes/${node.id}`);
  };

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
      }}
      variant="outlined"
    >
      <CardContent>
        <Grid container>
          <Grid item p={1} alignItems="center">
            <LabelIcon color={NodeLabelIconData[node.type].primary} />
          </Grid>

          <Grid item xs>
            <Typography
              variant="h6"
              sx={{ cursor: "pointer" }}
              onClick={navigateToNode}
            >
              {node.title.shorten(40)}

              {readonly && (
                <IconButton size="small">
                  <Lock fontSize="small" />
                </IconButton>
              )}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {node.shortDescription?.shorten(70) || "N/A"}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs
            justifyContent="end"
          >
            <Stack
              height="fit-content"
              direction="row"
              flexWrap="nowrap"
              gap={1}
            >
              <RelavantBoards boards={node.boards} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default NodeCard;
