import { makeAutoObservable, runInAction } from "mobx";

import { addEdge, Edge, Node } from "reactflow";

import { EdgeData } from "../models/edgeData";
import { GraphData, GraphNodeData } from "../models/graphData";

import agent from "../api/agent";
import { EdgeType } from "../models/edgeType";

export default class GraphStore {
  graph: {
    nodes: Node<GraphNodeData>[];
    edges: Edge<EdgeData>[];

    setNodes?: React.Dispatch<React.SetStateAction<Node<GraphNodeData>[]>>;
    setEdges?: React.Dispatch<React.SetStateAction<Edge<EdgeData>[]>>;
  } = {
      nodes: [],
      edges: [],
    };

  selectedBoardId: string | null = null;
  selectedNode: Node<GraphNodeData> | null = null;
  selectedEdge: Edge<EdgeData> | null = null;

  edgeTypes: EdgeType[] = [];

  loading = false;
  editMode = false;
  showId = false;
  isDirty = false;

  edgeIdsToBeDeleted: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsDirty(value: boolean) {
    this.isDirty = value;
  }

  setShowId(value: boolean) {
    this.showId = value;
  }

  async loadGraphData(boardId: string) {
    this.setLoading(true);
    this.graph = { nodes: [], edges: [] };

    try {
      const graphData = await agent.Graphs.getBoardsStaticGraph(boardId);
      const reactFlowData = GraphStore.transformFromGraphDataToReactFlowData(graphData);

      this.setGraphNodes(reactFlowData.nodes);
      this.setGraphEdges(reactFlowData.edges);

      runInAction(() => this.selectedBoardId = boardId);
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  };

  async loadEdgeTypes() {
    try {
      const edgeTypes = await agent.Graphs.listEdgeTypes();
      if (edgeTypes) {
        runInAction(async () => {
          this.edgeTypes = edgeTypes;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  setLoading(state: boolean) {
    this.loading = state;
  }

  setSelectedNode(node: Node<GraphNodeData> | null) {
    this.selectedNode = node;
  }

  setSelectedEdge(edge: Edge<EdgeData> | null) {
    this.selectedEdge = edge;
  }

  resetEdgeIdsToBeDeleted() {
    this.edgeIdsToBeDeleted = [];
  };

  addEdgeIdToBeDeleted(edgeId: string) {
    this.edgeIdsToBeDeleted = [...this.edgeIdsToBeDeleted, edgeId];
  };

  setGraphNodes(nodes: Node<GraphNodeData>[]) {
    this.graph.nodes = nodes;
    this.graph.setNodes?.call(null, nodes);
  }

  setGraphEdges(edges: Edge<EdgeData>[]) {
    this.graph.edges = edges;
    this.graph.setEdges?.call(null, edges);
  }

  initGraphSetters(setters: { setNodes?: React.Dispatch<React.SetStateAction<Node<GraphNodeData>[]>>, setEdges?: React.Dispatch<React.SetStateAction<Edge<EdgeData>[]>> }) {
    this.graph.setNodes = setters.setNodes;
    this.graph.setEdges = setters.setEdges;
  }

  showBoardNode(data: GraphNodeData) {
    const showBoardNodesEdges = async (nodeId: string) => {
      const edgesData = await agent.Nodes.listEdges(nodeId);
      if (!edgesData) return;

      const boardNodeIds = this.graph.nodes.map(({ id }) => id);

      // add missing edges which have both source and target nodes in graph
      edgesData.filter(
        (edge) =>
          boardNodeIds.includes(edge.sourceNodeId) &&
          boardNodeIds.includes(edge.targetNodeId)
      ).forEach((edge) => {
        this.setGraphEdges(
          addEdge(
            {
              id: edge.id,
              source: edge.sourceNodeId,
              target: edge.targetNodeId,
              label: edge.type,
              data: edge,
            },
            this.graph.edges
          )
        );
      });
    };

    // validations
    if (!data.title || data.title.length < 4) return;
    if (!data.type) return;

    this.graph.nodes.forEach((n) => (n.selected = false));

    this.setGraphNodes(
      this.graph.nodes.concat({
        id: data.nodeId,
        position: data.position,
        data: data,
        selected: true
      })
    );

    showBoardNodesEdges(data.nodeId);
    this.setIsDirty(true);
  };

  changeEdgeType(edgeId: string, type: string) {
    const edge = this.graph.edges.find(edge => edge.id === edgeId);

    if (!edge) return;

    edge.label = type;
    this.setGraphEdges(this.graph.edges);
    this.setSelectedEdge(edge);
    this.setIsDirty(true);
  }

  removeEdge(edgeId: string) {
    const filtered = this.graph.edges.filter(edge => edge.id !== edgeId)

    if (filtered.length === this.graph.edges.length) return;

    this.addEdgeIdToBeDeleted(edgeId);

    this.setGraphEdges(filtered);

    if (this.selectedEdge?.id === edgeId)
      this.setSelectedEdge(null);

    this.setIsDirty(true);
  }

  async saveGraphDataFromStaticReactFlow(
    rfNodes: Node[],
    rfEdges: Edge[],
    deletedEdgeIds: string[],
    boardId: string
  ) {
    const data = GraphStore.transformFromReactFlowDataToGraphData(rfNodes, rfEdges);
    await agent.Graphs.saveBoardsGraph(boardId, data, deletedEdgeIds);
    await this.loadGraphData(boardId);
  };

  async quickSaveGraph() {
    if (!this.selectedBoardId) return false;

    await this.saveGraphDataFromStaticReactFlow(
      this.graph.nodes,
      this.graph.edges,
      this.edgeIdsToBeDeleted,
      this.selectedBoardId
    )

    this.resetEdgeIdsToBeDeleted();
    this.setIsDirty(false)
    return true;
  }

  static transformFromReactFlowDataToGraphData(
    rfNodes: Node<GraphNodeData>[],
    rfEdges: Edge<EdgeData>[]
  ): GraphData {
    var data: GraphData = { nodes: [], edges: [] };

    data.nodes = rfNodes.map((node) => {
      return {
        ...node.data,
        position: node.position,
      };
    });

    data.edges = rfEdges.map((edge) => {
      return {
        id: edge.id,
        sourceNodeId: edge.source,
        targetNodeId: edge.target,
        type: edge.label!.toString(),
      };
    });

    return data;
  };

  static transformFromGraphDataToReactFlowData(graphData: GraphData) {
    const nodes = graphData.nodes
      .map((data) => ({
        id: data.nodeId,
        position: data.position,
        data: data,
      }));

    const edges = graphData.edges
      .map((data) => ({
        id: data.id,
        source: data.sourceNodeId,
        target: data.targetNodeId,
        label: data.type,
        className: "normal-edge",
        data: data,
      }));

    return {
      nodes,
      edges,
    };
  }
}
