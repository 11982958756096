
export const getTranslation = (lang = "en") => {
    switch (lang) {
        case "en":
            return {
                email: "Email",
                password: "Password",
                login: "Login",
                toggleLanguage: "Toogle language",
                signIn: "Sign in",
                signUp: "Sign up",
            }

        case "de":
            return {
                email: "Email",
                password: "Passwort",
                login: "Anmelden",
                toggleLanguage: "Sprache wechseln",
                signIn: "Einloggen",
                signUp: "Registrieren",
            }
    }
}