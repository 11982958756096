import { makeAutoObservable, observable } from "mobx";
import { getTranslation } from "./translation";

export default class TranslationStore {
    lang = "en";
    i18n = getTranslation(this.lang);
    
    constructor() {
        makeAutoObservable(this, {
            lang: observable,
            i18n: observable,
        });
    }

    toggleLanguage = () => {
        this.lang = this.lang === "en" ? "de" : "en";
        this.i18n = getTranslation(this.lang);
    }
}