import { Button } from "@mui/material";
import { useFormikContext } from "formik";

export interface DeleteButtonParams {
    onClick: Function
}

export const DeleteButtonMuiFormik = ({ onClick }: DeleteButtonParams) => {
    const { dirty } = useFormikContext();
    return <Button color='error' disabled={dirty} variant='outlined' onClick={() => { onClick() }}>Löschen</Button>
};