import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";


interface AddButtonParams {
    onClick: Function
}

export default function AddButton({ onClick }: AddButtonParams) {
    return (<IconButton color="default" sx={{
        ':hover': {
            color: 'success.main',
        },
    }}
        onClick={() => { onClick() }}
    >
        <Add fontSize={"large"}></Add>
    </IconButton>);
}