import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import TextInputMuiFormik from "../../app/common/form/muiFormikComponents/TextInputMuiFormik";

export default observer(function LoginForm() {
    const { accountStore, translationStore : t } = useStore();

    return (
        <Formik
            initialValues={{ email: '', password: '', error: null }}
            onSubmit={(values, { setErrors }) =>
            accountStore.login(values).catch(error => setErrors({ error: 'Invalid email or password' }))}
        >
            {({ handleSubmit, isSubmitting, errors }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Login to CoKoMo' color="teal" textAlign="center" />
                    <TextInputMuiFormik label={t.i18n?.email} name='email' />
                    <TextInputMuiFormik label={t.i18n?.password} name='password' type='password' />
                    <ErrorMessage name='error' render={() => 
                        <Label style={{ marginBottom: 10 }} basic color='red' content={errors.error} />} />
                    <Button loading={isSubmitting} positive content={t.i18n?.login} type="submit" fluid />
                </Form>
                
            )}

        </Formik>
    )
})