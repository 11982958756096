import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import SecondaryScreenWrapper from '../../../../app/common/SecondaryScreenWrapper';
import { router } from '../../../../app/router/Routes';
import { useStore } from '../../../../app/stores/store';

export default observer(function EditLearningGoalScreen() {
  const { id, lgsId } = useParams();
  const { nodeStore, graphStore } = useStore()
  const { selectedNode, updateLearningGoalStatement } = nodeStore;

  const [learningGoalId, setLearningGoalId] = useState("");
  const [desc, setDesc] = useState("");

  useEffect(() => {
    nodeStore.loadCompetenceLevels();
    graphStore.loadEdgeTypes();
  }, [nodeStore, graphStore]);

  useEffect(() => {
    if (!id) return;
    nodeStore.loadNode(id);
  }, [id, nodeStore])

  useEffect(() => {
    const lgs = selectedNode?.learningGoals.flatMap(e => e.learningGoalStatements).find(e => e.id === lgsId);
    if (!lgs) return;
    setLearningGoalId(lgs.learningGoalId);
    setDesc(lgs.content);
  }, [lgsId, selectedNode?.learningGoals])

  const handleSave = () => {
    if (!lgsId) return;

    updateLearningGoalStatement({
      id: lgsId,
      learningGoalId: learningGoalId,
      content: "",
    }, desc).then(handleClose)
  }

  const handleClose = () => {
    router.navigate(`/nodes/${selectedNode?.id}`)
  }

  const isValidToCreate = !!(learningGoalId?.trim() && desc?.trim());

  return (
    <SecondaryScreenWrapper
      endNode={
        <>
          <Grid item>
            <Button variant='outlined' onClick={handleClose}
              disabled={!isValidToCreate}
            >
              Abbrechen
            </Button>
          </Grid>

          <Grid item>
            <Button variant='outlined' onClick={handleSave}
              disabled={!isValidToCreate}
            >
              Speichern
            </Button>
          </Grid>
        </>
      }
    >
      {
        selectedNode &&
        <Grid container pt={4} px={6}>

          {/* TITLE */}
          <Grid item xs={12} py={2}>
            <Typography fontSize="22px">
              Lernziel bearbeiten
            </Typography>
          </Grid>


          {/* CHOOSE LEARNING GOAL */}
          <Grid item xs={12} py={2}>
            <Typography fontSize="14px">
              Bitte wählen Sie Lernzielstufe aus:
            </Typography>

            <RadioGroup
              value={learningGoalId}
              onChange={({ target: { value } }) => setLearningGoalId(value)}
            >
              <Grid container padding={2} gap={1}>
                {
                  selectedNode.learningGoals.map(({ id, competenceLevel }) => (
                    <Grid key={`radio_form_node_type_${id}`} item>
                      <FormControlLabel
                        sx={{
                          border: "1px solid #CED4DA",
                          width: "fit-content",
                          paddingRight: "10px",
                          borderRadius: "8px",
                        }}
                        value={id}
                        control={<Radio />}
                        label={
                          <Typography fontWeight={600} color="text.secondary">
                            {competenceLevel.number} - {competenceLevel.title}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </RadioGroup>
          </Grid>

          {/* DESCRIPTION */}
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              rows={40}
              placeholder="Bitte beschreiben Sie den neuen Komptenzenpunkt"
              variant="outlined"
              label="Kurzbeschreibung"
              InputProps={{
                sx: {
                  ":before": {
                    borderBottom: "none",
                  },
                },
              }}
              value={desc}
              onChange={({ target: { value } }) => setDesc(value)}
            />

            <Typography variant="caption" paddingLeft={2}>
              Max. XX Zeichen
            </Typography>
          </Grid>

        </Grid>
      }
    </SecondaryScreenWrapper>
  )
})
