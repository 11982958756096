import { Circle, PlayArrow } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { Observer } from "mobx-react-lite";
import { Edge, Handle, Node, NodeProps, Position, useReactFlow } from "reactflow";
import { EdgeData } from "../../../app/models/edgeData";
import { GraphNodeData } from "../../../app/models/graphData";
import { useStore } from "../../../app/stores/store";
import { NodeLabelIconData } from "../../nodes/NodeCardLabelIcon";

enum NodeState {
  Primary,
  Secondary,
  Disable,
}

export default function CustomNode({
  id,
  data,
  isConnectable,
}: NodeProps<GraphNodeData>) {
  const { graphStore } = useStore();
  const reactflow = useReactFlow();

  const background = NodeLabelIconData[data.type];

  return (
    <Observer>
      {() => {
        const nodeState = getNodeState(
          id,
          reactflow.getEdges(),
          graphStore.selectedNode,
          graphStore.selectedEdge
        );

        return (
          <div
            style={{
              borderRadius: "9px",
              padding: 4,
              ...(nodeState === NodeState.Primary
                ? {
                  color: "#FFFFFF",
                  background: background.primary,
                }
                : nodeState === NodeState.Secondary
                  ? {
                    color: "#141218",
                    background: background.secondary,
                  }
                  : {
                    color: "#141218",
                    background: "white",
                    border: "1px solid #141218",
                    opacity: "0.2",
                  }),
            }}
          >
            <Handle
              type="target"
              position={Position.Top}
              isConnectable={isConnectable}
            >
              <PlayArrow
                sx={{
                  transform: "translate(-50%,0) rotate(90deg)",
                  top: "-4px",
                  left: "auto",
                  right: "auto",
                  position: "absolute",
                  fontSize: "16px",
                  color: "#FFFFFF",
                  pointerEvents: "none",
                }}
              />

              <PlayArrow
                sx={{
                  transform: "translate(-50%,0) rotate(90deg)",
                  top: "-1px",
                  left: "auto",
                  right: "auto",
                  position: "absolute",
                  fontSize: "10px",
                  color: "#79747E",
                  pointerEvents: "none",
                }}
              />
            </Handle>

            <Typography fontWeight={700}>{data.title}</Typography>

            <Box
              hidden={!graphStore.showId}
              className="custom-node-additional-details"
            >
              <Divider sx={{ margin: 0 }} />

              <Typography variant="caption" fontSize={6}>
                {id}
              </Typography>
            </Box>

            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={isConnectable}
            >
              <Circle
                sx={{
                  transform: "translate(-50%,0)",
                  bottom: 0,
                  left: "auto",
                  right: "auto",
                  position: "absolute",
                  fontSize: "8px",
                  color: "#FFFFFF",
                  pointerEvents: "none",
                }}
              />

              <Circle
                sx={{
                  transform: "translate(-50%,0)",
                  bottom: "1px",
                  left: "auto",
                  right: "auto",
                  position: "absolute",
                  fontSize: "6px",
                  color: "#79747E",
                  pointerEvents: "none",
                }}
              />
            </Handle>
          </div>
        );
      }}
    </Observer>
  );
}

function getNodeState(
  nodeId: string,
  edges: Edge[],
  selectedNode: Node<GraphNodeData> | null,
  selectedEdge: Edge<EdgeData> | null
): NodeState {
  if (!!!selectedNode) {
    if (!!!selectedEdge) {
      return NodeState.Secondary;
    }

    return selectedEdge.source === nodeId || selectedEdge.target === nodeId
      ? NodeState.Primary
      : NodeState.Disable;
  }

  if (selectedNode.id === nodeId) return NodeState.Primary;

  if (
    edges.find(
      (edge) =>
        (nodeId === edge.target && selectedNode.id === edge.source) ||
        (nodeId === edge.source && selectedNode.id === edge.target)
    )
  )
    return NodeState.Secondary;

  return NodeState.Disable;
}
