import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Container, Header, Segment, Image, ButtonGroup, Divider } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";
import { fetchGoogleIdToken } from "../../app/services/firbaseService";
import { fetchMeinBildungsraumUserCode, oAuthLoginMeinBildungsraumUser } from "../../app/services/meinBildungsraumOAuth/meinBildungsraumOAuthService";
import { useEffect } from "react";

export default observer(function HomePage() {
  const { accountStore, modalStore, translationStore: t } = useStore();

  async function GoogleLogin() {
    var idToken = await fetchGoogleIdToken();
    accountStore.googleOAuth(idToken);
  }

  async function MeinBildungsraumLogin() {
    await fetchMeinBildungsraumUserCode();
  }

  //Hook to retrieve MeinBildungsraum userCode
  useEffect(() => {
    if (window.location.search) oAuthLoginMeinBildungsraumUser(accountStore.meinBildungsraumOAuth)
  }, [accountStore.meinBildungsraumOAuth]);

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Header as="h1" inverted>
          <Image
            size="massive"
            src="/assets/logo.png"
            alt="logo"
            style={{ marginBottom: 12 }}
          />
          CoKoMo
        </Header>
        {accountStore.isLoggedIn ? (
          <>
            <Header
              as="h2"
              inverted
              content={`Welcome back ${accountStore.user?.displayName}`}
            />
            <Button as={Link} to="/boards" size="huge" inverted>
              Lets start!
            </Button>
          </>
        ) : (
          <ButtonGroup vertical >
            <Button
              onClick={() => modalStore.openModal(<LoginForm />)}
              size="huge"
              inverted
              style={{ marginBottom: "0.5em" }}
            >
              CoKoMo {t.i18n?.login}
            </Button>
            <Button onClick={() => GoogleLogin()} size="huge" inverted style={{ marginBottom: "0.5em" }}>
              Google {t.i18n?.signIn}
            </Button>
            <Button onClick={() => MeinBildungsraumLogin()} size="huge" inverted icon>
              <Image src="/assets/mein-bildungsraum.png" size="medium" style={{ marginBottom: "0.5em" }} />
              {t.i18n?.signIn}
            </Button>
            <Segment >
              <Image src="/assets/sponsor-2.png" size="small" />
              <Divider></Divider>
              <Image src="/assets/sponsor-1.png" size="medium" />
            </Segment>
          </ButtonGroup>
        )}
      </Container>
    </Segment>
  );
});