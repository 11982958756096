import { router } from "../../app/router/Routes";

import { Lock } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Board } from "../../app/models/board";

interface Params {
  board: Board;
}

function BoardCard({ board }: Params) {
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      }}
      variant="outlined"
      onClick={() => router.navigate(`graphEditor/${board.id}`)}
    >
      <CardContent sx={{ flex: "1" }}>
        <Box maxWidth="400px">
          <Typography variant="caption" color="text.secondary">
            {formatDate(board.modifiedOn)}
          </Typography>

          <Typography variant="h6" paddingY={1} noWrap>
            {board.title}
          </Typography>

          <Typography variant="body2" noWrap>
            {board.description || "N/A"}
          </Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ flex: "0" }}>
        <Grid container justifyContent="end">
          {board.readonly ? (
            <Tooltip title="Readonly board">
              <IconButton disableRipple>
                <Lock />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

function formatDate(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  const yyyy = String(date.getFullYear()).padStart(4, "0");
  const MM = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const mm = String(date.getMinutes()).padStart(2, "0");

  return `${yyyy}.${MM}.${dd} ${hh}:${mm}`;
}

export default BoardCard;
