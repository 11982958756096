import { Box, Grid, Button, Typography } from "@mui/material";
import { East, Apartment } from "@mui/icons-material";
import { useStore } from "../../app/stores/store";
import { toast } from "react-toastify";

export default function DraftOrganizationSyncScreen() {

    const { orgaStore: oS } = useStore()

    const handleCopyOrganization = () => {
        try {
            oS.updateFinalDraftOrganization()
            toast.success('Inhalte übertragen')
        } catch (error) {
            toast.error('Etwas lief schief.')
        }
    }

    return (
        <Box height={'calc(100vh - 155px)'}>
            <Grid container spacing={2} >
                <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={8} >
                        <Typography variant="h4" color={'primary'}>Vorlagen Organisation übertragen</Typography>
                    </Grid>
                </Grid>
                <Grid marginTop={'20px'} item container xs={8} spacing={2}>
                    <Grid item container xs={4}>
                        <Grid item>
                            <Apartment sx={{ fontSize: '2.2rem', color: 'primary.light' }} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" color={'primary'}>Vorlagenentwurf</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={3} >
                        <Grid item>
                            <Button onClick={handleCopyOrganization} variant="outlined" endIcon={<East />}>Inhalte überschreiben</Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} justifyContent={'flex-end'}>
                        <Apartment sx={{ fontSize: '2.2rem', color: 'primary.light' }} />
                        <Typography variant="h5" color={'primary'}>Aktuelle Vorlage</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
}