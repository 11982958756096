import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBIecPAhTevFzgQBz6MhkVk2IT1WiyFqw4",
    authDomain: "cokomo-b55c9.firebaseapp.com",
    projectId: "cokomo-b55c9",
    storageBucket: "cokomo-b55c9.appspot.com",
    messagingSenderId: "84007162682",
    appId: "1:84007162682:web:1ad73936d6e05cb262b603"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

const auth = getAuth();

export const fetchGoogleIdToken = () => {
    return new Promise<string>((resolve, reject) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                if (!credential) return reject("Credential does not exist");
                // Google Access Token. Used to access the Google APIs.
                const token = credential.accessToken;
                if (!token) return reject("Token does not exist");
                // Id Token. Used for backend indentify user account
                auth.currentUser?.getIdToken(/*force refresh token*/ true)
                    .then(idToken => resolve(idToken))
            }).catch((error) => {
                reject(error);
            });
    })
}


