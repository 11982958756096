import { CheckOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { action, makeObservable, observable } from "mobx";
import React, { useMemo, useState } from "react";

type MultiChoicesBaseItem = {
  render: React.ReactNode | string | ((value: boolean) => React.ReactNode);
  value?: boolean;
  isDefault?: boolean;
}

type MultiChoicesBaseSection = MultiChoicesBaseItem[] | undefined

type MultiChoicesBaseValue = MultiChoicesBaseSection[]

function MultiChoicesMenuBase(props: {
  icon?: React.ReactNode;
  title: string;
  value: MultiChoicesBaseSection[];
  onValueChange?: (value: MultiChoicesBaseSection[]) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const normalized = useMemo(() =>
    props.value.map(section => {
      if (!section) return undefined;

      const sectionDefault = section.findIndex(({ isDefault }) => isDefault);
      const newSection = section.map(item => ({ ...item }))

      if (sectionDefault >= 0 && !newSection.find(({ value }) => value)) {
        newSection[sectionDefault].value = true;
      }

      return newSection;
    }),
    [props.value]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickChoiceFactory = (sectionIdx: number, itemIdx: number) => {
    return () => {
      if (!props.onValueChange) return;

      props.onValueChange(normalized.map((section, sIdx) => {
        if (!section || sIdx !== sectionIdx) return section;

        return section.map((item, iIdx) => ({
          ...item,
          value: iIdx === itemIdx && !item.value
        }))
      }));
    };
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={props.icon}
        onClick={handleClick}
        sx={{
          borderColor: "#D0D0D0",
          height: "100%",
          color: "#404040",
          borderRadius: "10px",
        }}
      >
        <Typography fontWeight="600">
          {props.title}
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "10px"
          }
        }}
        sx={{
          marginTop: "10px",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {normalized.map((section, sectionIdx) => (
          section ?
            section.map((item, itemIdx) => (
              <MenuItem
                key={`multi_choices_item_${sectionIdx}-${itemIdx}`}
                onClick={onClickChoiceFactory(sectionIdx, itemIdx)}
              >
                {
                  typeof item.render !== "function" ? item.render : item.render(!!item.value)
                }
              </MenuItem>
            ))
            :
            <Divider key={`multi_choices_divider_${sectionIdx}`} sx={{ mx: 1 }} />
        ))}
      </Menu >
    </>
  )
}

type MultiChoicesItem = {
  icon?: React.ReactNode,
  content: string;
  value?: boolean;
  isDefault?: boolean;
}

type MultiChoicesSection = MultiChoicesItem[] | undefined

type MultiChoicesValue = MultiChoicesSection[]

function MultiChoicesMenu(props: {
  icon?: React.ReactNode;
  title: string;
  value: MultiChoicesValue;
  onValueChange?: (value: MultiChoicesValue) => void;
}) {
  const rendererFactory = (item: MultiChoicesItem) => {
    return (value: boolean) =>
    (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" alignItems="center" sx={{ gap: "10px" }}>
          {item.icon}
          <Typography fontWeight={item.value ? 500 : 400}>{item.content}</Typography>
        </Box>

        <Box
          pl={4}
          sx={{
            opacity: value ? 1 : 0
          }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="999px"
            bgcolor="#7B73DB"
            width="24px" height="24px"
          >
            <CheckOutlined fontSize="small" htmlColor="white" />
          </Box>
        </Box>
      </Box>
    )
  }

  const value = props.value.map((section) => section?.map(item => ({
    render: rendererFactory(item),
    value: item.value,
    isDefault: item.isDefault,
  })) ?? undefined);

  const onValueChange = (value: MultiChoicesBaseSection[]) => {
    if (!props.onValueChange) return;

    props.onValueChange(props.value.map((section, sIdx) => {
      if (!section) return section;

      return section.map((item, iIdx) => ({
        ...item,
        value: value[sIdx]?.[iIdx].value ?? false
      }))
    }));
  };

  return MultiChoicesMenuBase({
    title: props.title,
    icon: props.icon,
    value: value,
    onValueChange: onValueChange,
  })
}

abstract class MultiChoicesStore {

  constructor(initValue: MultiChoicesValue) {
    this.value = initValue;

    makeObservable(this, {
      value: observable,
      setValue: action,
    })
  }

  value: MultiChoicesValue;

  setValue(value: MultiChoicesValue) {
    this.value = value;
  }

  getSectionValue(section: number): number {
    return MultiChoicesStore.getSectionValue(this.value, section);
  }

  /**
   * Get index of checked item in section, if there is no item that checked, then return undefined
   * @param section index of section, ignore divider (undefined)
   */
  static getSectionValue(value: MultiChoicesValue, section: number): number {
    return value.filter(section => !!section).at(section)?.findIndex(item => item.value) ?? -1;
  }
}

export {
  MultiChoicesMenu,
  MultiChoicesMenuBase,
  MultiChoicesStore,
  type MultiChoicesBaseValue,
  type MultiChoicesValue
};
