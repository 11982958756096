import { makeAutoObservable } from "mobx";
import React from "react";

interface Modal {
  open: boolean;
  body: JSX.Element | null;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;
  style?: React.CSSProperties;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    body: null,
    style: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  openModal = (
    content: JSX.Element,
    size:
      | "mini"
      | "tiny"
      | "small"
      | "large"
      | "fullscreen"
      | undefined = "mini",
    style: React.CSSProperties = {}
  ) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.size = size;
    this.modal.style = style;
  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
    this.modal.style = {};
  };
}
