import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import {
  Chip,
  Typography
} from "@mui/material";

import {
  ErrorOutline,
  InfoOutlined,
  LockOutlined
} from "@mui/icons-material";
import { useEffect } from "react";
import SecondaryScreenWrapper from "../../app/common/SecondaryScreenWrapper";
import { router } from "../../app/router/Routes";
import { useStore } from "../../app/stores/store";
import StaticReactFlowEditor from "./StaticReactFlowEditor";
import GraphDetailsModal from "./details/GraphDetailsModal";

export default observer(function GraphEditorScreen() {
  const { id } = useParams();
  const { boardStore, nodeStore, graphStore, modalStore } = useStore();
  const { selectedBoardId, selectedBoard } = boardStore;

  useEffect(() => {
    if (id) {
      boardStore.clearSelectedBoard();
      boardStore.loadBoard(id);
    }

    nodeStore.loadNodeTypes();
    graphStore.loadEdgeTypes();
  }, [nodeStore, graphStore, id, boardStore]);

  const handleBoardDelete = async () => {
    if (!selectedBoard) return;
    await boardStore.deleteBoard(selectedBoard.id);
    modalStore.closeModal();
    await router.navigate("/boards");
  };

  return (
    <SecondaryScreenWrapper
      loading={!boardStore.selectedBoard}
      title={boardStore.loading ? "Loading" : boardStore.selectedBoard?.title || "Kein Board ausgewählt"}
      items={[
        {
          icon: <InfoOutlined htmlColor="#000" />,
          label: "Eigenschaften",
          onClick: () =>
            modalStore.openModal(<GraphDetailsModal />, "small", {
              padding: "0px 20px"
            }),
        },
        // {
        //   icon: <RestoreOutlined htmlColor="#000" />,
        //   label: "Log Files",
        // },
        // {
        //   icon: <AnalyticsOutlined htmlColor="#000" />,
        //   label: "Analytics",
        // },
        // undefined,
        // {
        //   icon: <DriveFileRenameOutline htmlColor="#000" />,
        //   label: "Unbenennen",
        // },
        // {
        //   icon: <DownloadOutlined htmlColor="#000" />,
        //   label: "Download",
        // },
        // {
        //   icon: <SaveOutlined htmlColor="#000" />,
        //   label: "Speichern",
        // },
        // {
        //   icon: <DeleteOutline htmlColor="#ff0000" />,
        //   label: "Board löschen",
        //   color: "#ff0000",
        //   onClick: () =>
        //     modalStore.openModal(
        //       <ConfirmModal
        //         title="Board löschen"
        //         text="Board wirklich löschen?"
        //         onConfirm={() => handleBoardDelete()}
        //         onCancel={() => {
        //           modalStore.closeModal();
        //         }}
        //       />,
        //       "mini"
        //     ),
        // },
      ]}
      endNode={
        <>
          {graphStore.isDirty && (
            <Chip
              sx={{
                padding: 1,
              }}
              label={<Typography>nicht gespeichert</Typography>}
              icon={<ErrorOutline />}
            />
          )}

          {boardStore.selectedBoard?.readonly && (
            <Chip
              sx={{
                padding: 1,
              }}
              color="primary"
              label={<Typography>Nur zur Ansicht</Typography>}
              icon={<LockOutlined />}
            />
          )}
        </>
      }
    >
      {selectedBoardId === id &&
        <StaticReactFlowEditor />
      }
    </SecondaryScreenWrapper>
  );
});
