import { ArrowDownwardOutlined, ArrowUpwardOutlined, DateRangeOutlined, FilterAltOutlined, PolylineOutlined, SortOutlined, TitleOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import LabelNotice from "../../app/common/LabelNotice";
import { MultiChoicesMenu, MultiChoicesStore } from "../../app/common/MultiChoicesMenu";
import SearchInput from "../../app/common/SearchInput";
import { useDebounce } from "../../app/common/UtilHooks";
import { NodeType } from "../../app/models/nodeData";
import { useStore } from "../../app/stores/store";
import NodeCard from "./NodeCard";
import { NodeLabelIconData } from "./NodeCardLabelIcon";

class FilterStore extends MultiChoicesStore {
  constructor() {
    super([
      [
        {
          content: "Prinzip",
        },
        {
          content: "Konzept",
        },
        {
          content: "Verfahren",
        },
      ],
      undefined,
      [
        {
          content: "Alle",
          isDefault: true,
        },
        {
          content: "Editierbar",
        },
        {
          content: "Schreibgeschützt",
        },
      ]
    ])

    makeObservable(this, {
      type: computed,
      visibility: computed,
    })
  }

  public get type() {
    return this.getSectionValue(0);
  }

  public get visibility() {
    return this.getSectionValue(1);
  }
}

class SortStore extends MultiChoicesStore {
  constructor() {
    super([
      [
        {
          icon: <TitleOutlined />,
          content: "Name",
          isDefault: true,
        },
        {
          icon: <PolylineOutlined />,
          content: "Anzahl Verwendung",
        },
        {
          icon: <DateRangeOutlined />,
          content: "Erstellungsdatum",
        },
        {
          icon: <DateRangeOutlined />,
          content: "Letzte Bearbeitung",
        },
      ],
      undefined,
      [
        {
          icon: <ArrowUpwardOutlined />,
          content: "Aufsteigend",
          isDefault: true,
        },
        {
          icon: <ArrowDownwardOutlined />,
          content: "Absteigend",
        },
      ]
    ])

    makeObservable(this, {
      field: computed,
      direction: computed,
    })
  }

  public get field() {
    return this.getSectionValue(0);
  }

  public get direction() {
    return this.getSectionValue(1);
  }
}

export default observer(function NodesScreen() {
  const { nodeStore, accountStore: { user } } = useStore();
  const { loadNodes, clearNodes, nodes, clearSelectedNode, loading } =
    nodeStore;

  const [searchString, setSearchString] = useState("");
  const debouncedSearchString = useDebounce(searchString.trim(), 500);

  const [filter] = useState(() => new FilterStore())
  const [sort] = useState(() => new SortStore())

  useEffect(() => {
    clearNodes();
    clearSelectedNode();
    return () => {
      clearNodes();
      clearSelectedNode();
    };
  }, [clearNodes, clearSelectedNode]);

  useEffect(() => {
    if (debouncedSearchString.length > 3) {
      loadNodes(debouncedSearchString);
    } else {
      clearNodes();
    }
  }, [clearNodes, debouncedSearchString, loadNodes]);

  const allNodes = useMemo(() => {
    let result = nodes?.slice() ?? [];

    const filterByNodeType = filter.type;
    const filterByNodeVisibility = filter.visibility;

    if (filterByNodeType >= 0) {
      result = result.filter(node => node.type === [
        NodeType.Principle,
        NodeType.Concept,
        NodeType.Procedure,
      ][filterByNodeType]);
    }

    switch (filterByNodeVisibility) {
      case 0:
        // "All" means no filter
        break;
      case 1:
        result = result.filter(node => user?.organization.id === node?.organizationId);
        break;
      case 2:
        result = result.filter(node => user?.organization.id !== node?.organizationId);
        break;
      default:
        break;
    }

    const sortByField = sort.field;
    const sortDirection = sort.direction;

    switch (sortByField) {
      case 0:
        // Name
        result = result.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 1:
        // Number of uses
        result = result.sort((a, b) => a.boards.length - b.boards.length);
        break;
      case 2:
        // Created At
        break;
      case 3:
        // Updated At
        break;
    }

    if (sortDirection === 1) {
      result = result.reverse()
    }

    return result;
  }, [nodes, filter.type, filter.visibility, sort.field, sort.direction, user?.organization.id]);

  return (
    <Box sx={{ overflow: "auto" }} height="100%" paddingX={10}>
      <Grid container alignContent="flex-start">
        <Grid item xs={12} paddingY={4} paddingX={2}>
          <Typography variant="h4">Kompetenzpunkte</Typography>
        </Grid>

        <Grid container item xs={12} padding={2} spacing={2}>
          <Grid container item md={12} lg={4} flexDirection="column">
            <Grid item>
              <Typography variant="h6">
                Insgesamt {allNodes?.length || 0} Kompetenzpunkte
              </Typography>
            </Grid>

            <Grid container item justifyContent="start">
              {Object.entries(NodeLabelIconData).map(([text, color]) => (
                <LabelNotice
                  key={`label_notice_${text}`}
                  color={color.primary}
                  text={
                    {
                      [NodeType.Principle]: "Prinzipien",
                      [NodeType.Concept]: "Konzepte",
                      [NodeType.Procedure]: "Verfahren",
                    }[text] ?? ""
                  }
                />
              ))}
            </Grid>
          </Grid>

          <Grid container item md={12} lg={8} justifyContent="end" alignItems="center">
            <Grid container item xs spacing={1} justifyContent="end">
              <Grid item>
                <MultiChoicesMenu
                  icon={<FilterAltOutlined />}
                  title="Filter"
                  value={filter.value}
                  onValueChange={filter.setValue.bind(filter)}
                />
              </Grid>

              <Grid item>
                <MultiChoicesMenu
                  icon={<SortOutlined />}
                  title="Sortierung"
                  value={sort.value}
                  onValueChange={sort.setValue.bind(sort)}
                />
              </Grid>

              <Grid item>
                <SearchInput
                  placeholder="Suche Kompetenzpunkte"
                  value={searchString}
                  setValue={setSearchString}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container paddingY={2} spacing={2} xs={12}>
          {loading ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              xs={12}
              height="calc(100vh - 300px)"
              minHeight="200px"
              borderRadius="20px"
              bgcolor="#F9F9F9"
            >
              <CircularProgress />
              <Typography variant="h5" pl={2}>
                Searching...
              </Typography>
            </Grid>
          ) : allNodes.length === 0 ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              sx={{
                borderStyle: "dashed",
                borderWidth: 1,
                borderRadius: 20,
              }}
            >
              <Typography variant="h5" pb={2}>
                Keine Kompetenzpunkte
              </Typography>
            </Grid>
          ) : (
            <>
              {allNodes?.map((node) => (
                <Grid item key={`gridItem_${node.id}`} height="120px" xs={12}>
                  <NodeCard node={node} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});
