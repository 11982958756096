import { AutorenewOutlined, CheckCircleOutline, EditOutlined, LightbulbOutlined, OpenInFullOutlined, SaveOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Grid, InputBase, Stack, Typography } from '@mui/material';
import katex from 'katex';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SecondaryScreenWrapper from '../../../app/common/SecondaryScreenWrapper';
import { useStore } from '../../../app/stores/store';
import { NodeLabelIconData } from '../NodeCardLabelIcon';
import RelavantBoards from '../RelavantBoards';

// @ts-ignore
import ImageResize from 'quill-image-resize';
import { router } from '../../../app/router/Routes';
import LearningGoalsSection from './learningGoals/LearningGoalsSection';


// @ts-ignore
window.katex = katex;
Quill.register('modules/imageResize', ImageResize);

export default observer(function NodeDetailsScreen() {
  const { id } = useParams();
  const { nodeStore, graphStore } = useStore()
  const { selectedNode } = nodeStore;

  useEffect(() => {
    nodeStore.loadNodeTypes();
    nodeStore.loadCompetenceLevels();
    graphStore.loadEdgeTypes();
  }, [nodeStore, graphStore]);

  useEffect(() => {
    if (!id) return;
    nodeStore.loadNode(id)
  }, [id, nodeStore])


  return (
    <SecondaryScreenWrapper
      title={selectedNode?.title ?? "Loading"}
    >
      {
        selectedNode &&
        <Grid container pt={4} px={6}>

          {/* TITLE */}
          <Grid item xs={12} py={2}>
            <Typography fontSize="32px">
              {selectedNode.title}
            </Typography>
          </Grid>

          {/* RELAVANT BOARDS */}
          <Grid item xs={12} pt={2} pb={1}>
            <Typography fontSize="22px">
              Relevante Boards
            </Typography>
          </Grid>

          <Grid item xs={12} container gap={1}>
            <RelavantBoards boards={selectedNode.boards} />
          </Grid>

          {/* BASIC INFORMATIONS & DETAILS */}
          <Grid item xs={12} container pt={4} spacing={6}>
            <BasicInformationSection />
            <DetailsSection />
          </Grid>

          {/* LEARNING GOALS */}
          <Grid item xs={12} container pt={6}>
            <Grid item xs={12}>
              <Typography fontSize="22px">
                Lernziele und ihre Definitionen
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" spacing={1} py={2}>
                <LightbulbOutlined fontSize="small" htmlColor='#483FA5' />

                <Typography>
                  Was sind Lernzielsätze?
                </Typography>

                <Link to="">
                  <Typography color="#483FA5">
                    mehr darüber
                  </Typography>
                </Link>
              </Stack>
            </Grid>

            <Grid item xs={6} >
              <Box display="flex" justifyContent="end">
                <Button
                  startIcon={<OpenInFullOutlined />}
                  sx={{ px: 2 }}
                  onClick={() => router.navigate(`/nodes/${id}/learning-goals`)}
                >
                  <Typography fontSize="14px" fontWeight={500} minWidth="70px">
                    Öffnen
                  </Typography>
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <LearningGoalsSection />
            </Grid>
          </Grid>

        </Grid>
      }
    </SecondaryScreenWrapper>
  )
})

const BasicInformationSection = observer(() => {
  const {
    nodeStore: { selectedNode, updateBaseData },
    accountStore: { user }
  } = useStore();
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false);

  const node = useMemo(() => selectedNode ? makeAutoObservable({ ...selectedNode }) : null, [selectedNode]);

  const readonly = user?.organization.id !== selectedNode?.organizationId;

  const saveHandle = () => {
    if (!node) return;

    if (JSON.stringify(node) === JSON.stringify(selectedNode)) return;

    setSaving(true);
    updateBaseData(node).finally(() => setSaving(false));
  }

  if (!node) return <></>

  return (
    <Grid item xs={12} lg={6} container>
      <Stack
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Typography fontSize="22px">Basisinformationen</Typography>

          {
            !readonly &&
            <ToggleEditButton
              value={editing}
              onValueChanged={setEditing}
              loading={saving}
              onSave={saveHandle}
            />
          }
        </Stack>

        <Stack
          py={2}
          spacing={2}
        >
          {
            Object.entries({
              "Titel": <InputBase
                fullWidth
                value={node.title}
                onChange={({ target: { value } }) => runInAction(() => node.title = value)}
                readOnly={!editing}
              />,
              "Alternative Begriffe": <>
                {
                  (JSON.parse(node.altTerms || "[]") as string[]).map((term, idx) => (
                    <Chip
                      key={`term_${idx}`}
                      label={<Typography maxWidth="100px" fontWeight={500}>{term}</Typography>}
                      sx={{
                        bgcolor: "#EBEBEB",
                        borderRadius: "8px",
                      }}
                    />
                  ))
                }
              </>,
              "Kategorie": Object.entries(NodeLabelIconData).map(([type, data]) => (
                (editing || type === node.type) &&
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  bgcolor={data.secondary}
                  width="fit-content"
                  px={1}
                  py={0.8}
                  borderRadius="8px"
                  sx={{
                    cursor: "default",
                    ...(

                      editing ?
                        type === node.type ?
                          {
                            bgcolor: data.primary,
                            color: "white"
                          } :
                          {
                            cursor: "pointer",
                            ":hover": {
                              bgcolor: data.primary,
                              color: "white"
                            }
                          } : {}
                    )
                  }}
                  onClick={() => editing && runInAction(() => node.type = type)}
                >
                  <CheckCircleOutline />
                  <Typography fontWeight={500}>
                    {data.text}
                  </Typography>
                </Stack>
              )),
              "Kurzbeschreibung": <InputBase
                fullWidth
                value={node.shortDescription}
                onChange={({ target: { value } }) => runInAction(() => node.shortDescription = value)}
                readOnly={!editing}
              />
            }).map(([title, render], idx) => (
              <Stack
                key={`basic_info_${idx}`}
                direction="row"
                bgcolor="#F9F9F9"
                p="12px"
                minHeight="60px"
                borderRadius="12px"
              >
                <Typography flex="3" fontSize="12px">{title}</Typography>
                <Box flex="7" gap={1} display="flex" overflow="auto" sx={{ scrollbarWidth: "none" }}>{render}</Box>
              </Stack>
            ))
          }
        </Stack>
      </Stack>
    </Grid >
  )
})

const DetailsSection = observer(() => {
  const {
    nodeStore: { selectedNode, updateRichDescription, loading },
    accountStore: { user }
  } = useStore()
  const [editing, setEditing] = useState(false)
  const [desc, setDesc] = useState(selectedNode?.richDescription);

  const readonly = user?.organization.id !== selectedNode?.organizationId;

  const saveHandle = () => {
    if (!selectedNode || !desc) return;

    if (desc === selectedNode.richDescription) return;

    updateRichDescription(selectedNode.id, desc);
    toast.success('Beschreibung gespeichert')
  }

  return (
    <Grid item xs={12} lg={6} container>
      <Stack width="100%" height="100%">
        <Stack
          flex="none"
          direction="row"
          justifyContent="space-between"
        >
          <Typography fontSize="22px">Details</Typography>

          {
            !readonly &&
            <ToggleEditButton
              value={editing}
              onValueChanged={setEditing}
              loading={loading}
              onSave={saveHandle}
            />
          }
        </Stack>

        <Stack
          flex="auto"
          py={2}
          spacing={2}
        >
          {
            editing ?
              <ReactQuill
                value={desc || ""}
                onChange={setDesc}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image'],
                    ['formula'],
                  ],
                  imageResize: {},
                }}
                style={{
                  height: "calc(100% - 40px)"
                }}
              />
              :
              <Box
                height="100%"
                maxHeight="450px"
                overflow="auto"
                bgcolor="#F9F9F9"
                p="12px"
                borderRadius="12px"
              >
                {

                  desc ?
                    <div
                      style={{ wordWrap: "break-word" }}
                      dangerouslySetInnerHTML={{ __html: addMaxWidthToImages(desc) }}
                    /> :
                    <Typography>Keine Angaben</Typography>
                }
              </Box>
          }
        </Stack>
      </Stack>
    </Grid>
  )
})

const ToggleEditButton = observer((props: {
  value: boolean;
  onValueChanged?: (value: boolean) => void;
  loading?: boolean;
  onSave?: () => void;
}) => {
  return (
    <Button
      startIcon={
        props.loading ? <AutorenewOutlined sx={{
          animation: "spin 1s infinite linear",
          "@keyframes spin": {
            from: {
              transform: "rotate(0deg)"
            },
            to: {
              transform: "rotate(360deg)"
            }
          }
        }} /> :
          props.value ? <SaveOutlined /> :
            <EditOutlined />
      }
      sx={{ px: 2 }}
      onClick={() => {
        if (props.onValueChanged) {
          props.onValueChanged(!props.value);

          props.value && props.onSave && props.onSave();
        }
      }}
      disabled={props.loading}
    >
      <Typography fontSize="14px" fontWeight={500} minWidth="70px">
        {props.loading || props.value ? "Speichern" : "Bearbeiten"}
      </Typography>
    </Button>
  )
})

function addMaxWidthToImages(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const images = doc.querySelectorAll('img');

  images.forEach(image => {
    image.style.maxWidth = '100%';
  });

  return doc.documentElement.outerHTML;
}
