/* eslint-disable no-extend-native */
export { };

declare global {
    interface Array<T> {
        duplicate(time: number): Array<T>;
    }

    interface String {
        shorten(maxLen: number): String;
    }
}

String.prototype.shorten = function (maxLen: number) {
    if (!this) return this;

    if (this.length > maxLen) {
        return this.substring(0, maxLen) + "...";
    }

    return this;
}

Array.prototype.duplicate = function (time: number) {
    if (time <= 0) return [];
    return Array(time).fill(null).flatMap(() => this);
};
