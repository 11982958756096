import {
  AnalyticsOutlined,
  Close,
  DeleteOutline,
  InfoOutlined,
  RestoreOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  TabProps,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import ConfirmModal from "../../../app/common/modals/ConfirmModal";
import { Board } from "../../../app/models/board";
import { router } from "../../../app/router/Routes";
import BoardStore from "../../../app/stores/boardStore";
import { useStore } from "../../../app/stores/store";

export default observer(function BoardInfoModal() {
  const { modalStore } = useStore();
  const [tab, setTab] = useState(0);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flex={1} maxWidth="400px">
          <Tabs value={tab} onChange={(_, t) => setTab(t)} variant="fullWidth">
            <Tab label="Eingenschaften" icon={<InfoOutlined />} />
            <Tab label="Log Files" icon={<RestoreOutlined />} />
            <Tab label="Analytics" icon={<AnalyticsOutlined />} />
          </Tabs>
        </Box>

        <Box flex={0}>
          <IconButton onClick={() => modalStore.closeModal()}>
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Divider />

      <Box py={1}>
        <GraphPropertiesTab value={tab} tabIndex={0} />
      </Box>
    </>
  );
});

const GraphPropertiesTab = observer((props: TabProps) => {
  class LocalStore {
    board: Board = {} as Board;

    get isDirty() {
      return (
        JSON.stringify(this.board) !== JSON.stringify(boardStore.selectedBoard)
      );
    }

    constructor() {
      makeAutoObservable(this);
    }

    init(boardStore: BoardStore) {
      // shallow clone selectedBoard -> board
      this.board = { ...boardStore.selectedBoard } as Board;
    }

    setTitle(value: string) {
      this.board.title = value;
    }

    setDescription(value: string) {
      this.board.description = value;
    }
  }

  const { boardStore, modalStore } = useStore();
  const { selectedBoard } = boardStore;
  const [localStore] = useState(() => new LocalStore());

  useEffect(() => localStore.init(boardStore), [boardStore, localStore]);

  if (props.tabIndex !== props.value) return <></>;

  const handleBoardDelete = async () => {
    if (!selectedBoard) return;
    await boardStore.deleteBoard(selectedBoard.id);
    modalStore.closeModal();
    await router.navigate("/boards");
  };

  const handleBoardSave = async () => {
    await boardStore?.update(localStore.board);

    await router.navigate("", {
      replace: false,
    });

    modalStore.closeModal();
  };

  return (
    <>
      {selectedBoard && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} pt={3}>
              <Typography>
                Zuletzt aktualisiert am{" "}
                {moment(selectedBoard.modifiedOn).format("DD.M.yyyy")}
              </Typography>
            </Grid>

            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Titel des Board"
                  value={localStore.board.title}
                  onChange={(e) => localStore.setTitle(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Beschreibung"
                  value={localStore.board.description}
                  onChange={(e) => localStore.setDescription(e.target.value)}
                  multiline
                  maxRows={10}
                />

                <Typography variant="caption" paddingLeft={2}>
                  Max. XX Zeichen
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid container pt={4} pb={2}>
            <Grid item xs>
              <Button
                startIcon={<DeleteOutline />}
                color="error"
                variant="contained"
                onClick={() =>
                  modalStore.openModal(
                    <ConfirmModal
                      title="Board löschen"
                      text="Board wirklich löschen?"
                      onConfirm={() => handleBoardDelete()}
                      onCancel={() => {
                        modalStore.closeModal();
                      }}
                    />,
                    "mini"
                  )
                }
              >
                Board löchen
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disabled={!localStore.isDirty}
                onClick={handleBoardSave}
              >
                Speichern
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});
