import { makeAutoObservable, runInAction } from "mobx";
import jwt_decode from "jwt-decode";
import agent from "../api/agent";

import { User, UserFormValues } from "../models/user";
import { router } from "../router/Routes";
import { store } from "./store";
import { oAuthLogoutMeinBildungsraumUser } from "../services/meinBildungsraumOAuth/meinBildungsraumOAuthService";

enum roleNames {
  superAdmin = "SuperAdmin",
  user = "User",
}

export default class AccountStore {
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  isMeinBildungsRaumUser = false;

  login = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      this.setUser(user);
      router.navigate("/boards");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  googleOAuth = async (token: string) => {
    try {
      const user = await agent.Account.googleOAuth(token);
      store.commonStore.setToken(user.token);
      this.setUser(user);
      router.navigate("/boards");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  meinBildungsraumOAuth = async (oAuthCode: string) => {
    try {
      const user = await agent.Account.meinBildungsraumOAuth(oAuthCode);
      store.commonStore.setToken(user.token);
      this.setUser(user);
      router.navigate("/boards");
      store.modalStore.closeModal();
      this.isMeinBildungsRaumUser = true;
    } catch (error) {
      throw error;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.register(creds);
      store.commonStore.setToken(user.token);
      this.setUser(user);
      router.navigate("/boards");
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  acceptTerms = async () => {
    try {
      agent.Account.acceptTerms();
    } catch (error) {
      throw error;
    }
  };

  setUser = (user: User) => {
    user.acceptedTermsOn = new Date(user.acceptedTermsOn);
    runInAction(() => (this.user = user));
  };

  logout = () => {
    store.commonStore.setToken(null);
    this.user = null;
    this.isMeinBildungsRaumUser && oAuthLogoutMeinBildungsraumUser();
    this.isMeinBildungsRaumUser = false;
    router.navigate("/");
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      this.setUser(user);
      runInAction(() => (this.user = user));
    } catch (error) {
      console.error(error);
    }
  };

  isAdmin = (): boolean => {
    let isAdmin = false;
    if (this.user?.token) {
      const decodedToken: Token = jwt_decode(this.user.token);
      if (decodedToken.role && decodedToken.role === roleNames.superAdmin)
        isAdmin = true;
    }
    return isAdmin;
  };

  get didAcceptTerms() {
    if (!this.user) return false;
    return this.user.acceptedTermsOn > new Date("2020.12.31");
  }

  setImage = (image: string) => {
    if (this.user) this.user.image = image;
  };

  setUserPhoto = (url: string) => {
    if (this.user) this.user.image = url;
  };

  setDisplayName = (name: string) => {
    if (this.user) this.user.displayName = name;
  };
}

interface Token {
  unique_name: string;
  nameid: string;
  email: string;
  groupsid: string;
  role: string;
  nbf: number;
  exp: number;
  iat: number;
}
