//used to not fire a request to the backend at every keystroke but only after the user stopped typing
import { useEffect, useState } from "react";

export function useDebounce(
  value: string,
  delay: number,
  immediateWhenEmpty: boolean = false
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    let calculatedDelay = delay;

    if (immediateWhenEmpty && value.length === 0) {
      calculatedDelay = 0;
    }

    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, calculatedDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay, immediateWhenEmpty]);

  return debouncedValue;
}
