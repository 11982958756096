import { List, styled } from "@mui/material";

interface StyledListProps {
    height: string | number;
    dense?: boolean;
}

export const ListWithBorders = styled(List)<StyledListProps>(({ height, dense = false }) => ({
    width: '100%',
    height: height,
    overflow: 'auto',
    dense: dense,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
    },
}));