import {
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Backdrop,
    DialogContentText
} from '@mui/material';

import React, { useState } from 'react';
import { store, useStore } from '../stores/store';

export default function UserTermsAcceptance() {

    const { accountStore } = useStore();

    const [checked, setChecked] = useState(false);

    const handleAccept = () => {
        try {
            accountStore.acceptTerms()
            store.commonStore.setUiBlocked(false)
        } catch (error) {
            console.error(error)
            store.commonStore.setUiBlocked(true)
        }
    };

    const handleDeny = () => {
        accountStore.logout()
    };

    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={() => { }}>
                <Dialog open={true}>
                    <DialogTitle>Nutzungsbedingungen</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Bitte stimmen Sie den aktuellen <a href="https://cokomo-it.de/docs/nutzungsbedingungen-app/" target="_blank">Nutzungsbedingungen</a> zu um fortzufahren.</DialogContentText>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            }
                            label="Ich habe die Nutzungsbedinungen gelesen und stimme zu."
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeny} color="secondary">
                            Ablehnen und ausloggen
                        </Button>
                        <Button
                            onClick={handleAccept}
                            color="primary"
                            disabled={!checked}
                        >
                            Zustimmen und weiter
                        </Button>
                    </DialogActions>
                </Dialog>
            </Backdrop>
        </div>)

}