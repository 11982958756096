import axios, { AxiosResponse } from "axios";
import { PaginatedResult } from "../models/pagination";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: any) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: any) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody)
}

export class BaseAgent<TClass, TClassEdit> {
    baseUrl: string = '';

    constructor(_baseUrl = '') {
        if (_baseUrl[0] !== '/') _baseUrl = '/' + _baseUrl;
        this.baseUrl = _baseUrl;
    }

    async listPaged(params: URLSearchParams) {
        return axios.get<PaginatedResult<TClass[]>>(`${this.baseUrl}`, { params })
            .then(responseBody)
    }

    async get(id: string) {
        return requests.get<TClass>(`${this.baseUrl}/${id}`)
    }

    async update(id: string, orga: TClassEdit) {
        return requests.put<TClass>(`${this.baseUrl}/${id}`, orga)
    }

    async create(orga: TClassEdit) {
        return requests.post<TClass>(`${this.baseUrl}`, orga)
    }

    async delete(id: string) {
        return requests.del<boolean>(`${this.baseUrl}/${id}`)
    }
}