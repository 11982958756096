import { useEffect } from "react";
import { useStore } from "../../stores/store";

export function FrontChannelLogout() {
    const store = useStore();
    const { accountStore } = store;

    useEffect(() => {
        accountStore.logout();
    }, [accountStore]);

    return (<></>)
}