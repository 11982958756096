import agent from "../api/agent";
import { UserDto, UserFormValuesDto } from "../models/user";
import { BaseStore } from "./baseStore";

export default class UserManagerStore extends BaseStore<UserDto, UserFormValuesDto> {
    constructor() {
        super(agent.UsersManager);
    }
    // eslint-disable-next-line
    passwordRequirements = new RegExp(/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{6,}/gm);

    setPassword = async (id: string, password: string) => {
        if (!this.passwordRequirements.test(password)) return false
        try {
            await agent.UsersManager.setPassword(id, password)
            return true
        } catch (error) {
            console.error(error)
        }
    }

    loadByOrganizationId = async (orgId: string) => {
        this.loading = true
        try {
            const respUsers = await agent.Organizations.listUsers(orgId)
            this.clearSelectedItem();
            this.clearRegistry();
            respUsers.forEach(u => {
                this.setItem(u);
            })
            this.loading = false;
        } catch (error) {
            console.error(error)
            this.loading = false;
        }
    }
}