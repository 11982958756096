import { SVGProps } from "react";

export function EdgeTypeIcon(props: {
  variant: ("C" | "S" | "R" | "P" | "IP" | "?") | string;
  color: string;
} & SVGProps<SVGSVGElement>) {
  let content = <></>;

  switch (props.variant) {
    case "?":
      content = (
        <path
          d="M11.7322 17.1477C11.3572 17.1477 11.0353 17.0152 10.7663 16.75C10.5012 16.4811 10.3686 16.1591 10.3686 15.7841C10.3686 15.4129 10.5012 15.0947 10.7663 14.8295C11.0353 14.5644 11.3572 14.4318 11.7322 14.4318C12.0959 14.4318 12.4141 14.5644 12.6868 14.8295C12.9595 15.0947 13.0959 15.4129 13.0959 15.7841C13.0959 16.0341 13.0315 16.2633 12.9027 16.4716C12.7777 16.6761 12.6129 16.8409 12.4084 16.9659C12.2038 17.0871 11.9785 17.1477 11.7322 17.1477ZM16.4979 17.1477C16.1229 17.1477 15.8009 17.0152 15.532 16.75C15.2668 16.4811 15.1342 16.1591 15.1342 15.7841C15.1342 15.4129 15.2668 15.0947 15.532 14.8295C15.8009 14.5644 16.1229 14.4318 16.4979 14.4318C16.8615 14.4318 17.1797 14.5644 17.4524 14.8295C17.7251 15.0947 17.8615 15.4129 17.8615 15.7841C17.8615 16.0341 17.7971 16.2633 17.6683 16.4716C17.5433 16.6761 17.3786 16.8409 17.174 16.9659C16.9695 17.0871 16.7441 17.1477 16.4979 17.1477ZM21.2635 17.1477C20.8885 17.1477 20.5665 17.0152 20.2976 16.75C20.0324 16.4811 19.8999 16.1591 19.8999 15.7841C19.8999 15.4129 20.0324 15.0947 20.2976 14.8295C20.5665 14.5644 20.8885 14.4318 21.2635 14.4318C21.6271 14.4318 21.9453 14.5644 22.218 14.8295C22.4908 15.0947 22.6271 15.4129 22.6271 15.7841C22.6271 16.0341 22.5627 16.2633 22.4339 16.4716C22.3089 16.6761 22.1442 16.8409 21.9396 16.9659C21.7351 17.0871 21.5097 17.1477 21.2635 17.1477Z"
          fill={props.color}
        />
      );
      break;
    case "C":
      content = (
        <path
          d="M21.2784 14.4375H18.7898C18.7443 14.1155 18.6515 13.8295 18.5114 13.5795C18.3712 13.3258 18.1913 13.1098 17.9716 12.9318C17.7519 12.7538 17.4981 12.6174 17.2102 12.5227C16.9261 12.428 16.6174 12.3807 16.2841 12.3807C15.6818 12.3807 15.1572 12.5303 14.7102 12.8295C14.2633 13.125 13.9167 13.5568 13.6705 14.125C13.4242 14.6894 13.3011 15.375 13.3011 16.1818C13.3011 17.0114 13.4242 17.7083 13.6705 18.2727C13.9205 18.8371 14.2689 19.2633 14.7159 19.5511C15.1629 19.839 15.6799 19.983 16.267 19.983C16.5966 19.983 16.9015 19.9394 17.1818 19.8523C17.4659 19.7652 17.7178 19.6383 17.9375 19.4716C18.1572 19.3011 18.339 19.0947 18.483 18.8523C18.6307 18.6098 18.733 18.3333 18.7898 18.0227L21.2784 18.0341C21.214 18.5682 21.053 19.0833 20.7955 19.5795C20.5417 20.072 20.1989 20.5133 19.767 20.9034C19.339 21.2898 18.8277 21.5966 18.233 21.8239C17.642 22.0473 16.9735 22.1591 16.2273 22.1591C15.1894 22.1591 14.2614 21.9242 13.4432 21.4545C12.6288 20.9848 11.9848 20.3049 11.5114 19.4148C11.0417 18.5246 10.8068 17.447 10.8068 16.1818C10.8068 14.9129 11.0455 13.8333 11.5227 12.9432C12 12.053 12.6477 11.375 13.4659 10.9091C14.2841 10.4394 15.2045 10.2045 16.2273 10.2045C16.9015 10.2045 17.5265 10.2992 18.1023 10.4886C18.6818 10.678 19.1951 10.9545 19.642 11.3182C20.089 11.678 20.4527 12.1193 20.733 12.642C21.017 13.1648 21.1989 13.7633 21.2784 14.4375Z"
          fill={props.color}
        />
      );
      break;
    case "S":
      content = (
        <path
          d="M18.3239 13.7102C18.2784 13.2519 18.0833 12.8958 17.7386 12.642C17.3939 12.3883 16.9261 12.2614 16.3352 12.2614C15.9337 12.2614 15.5947 12.3182 15.3182 12.4318C15.0417 12.5417 14.8295 12.6951 14.6818 12.892C14.5379 13.089 14.4659 13.3125 14.4659 13.5625C14.4583 13.7708 14.5019 13.9527 14.5966 14.108C14.6951 14.2633 14.8295 14.3977 15 14.5114C15.1705 14.6212 15.3674 14.7178 15.5909 14.8011C15.8144 14.8807 16.053 14.9489 16.3068 15.0057L17.3523 15.2557C17.8598 15.3693 18.3258 15.5208 18.75 15.7102C19.1742 15.8996 19.5417 16.1326 19.8523 16.4091C20.1629 16.6856 20.4034 17.0114 20.5739 17.3864C20.7481 17.7614 20.8371 18.1913 20.8409 18.6761C20.8371 19.3883 20.6553 20.0057 20.2955 20.5284C19.9394 21.0473 19.4242 21.4508 18.75 21.7386C18.0795 22.0227 17.2708 22.1648 16.3239 22.1648C15.3845 22.1648 14.5663 22.0208 13.8693 21.733C13.1761 21.4451 12.6345 21.0189 12.2443 20.4545C11.858 19.8864 11.6553 19.1837 11.6364 18.3466H14.017C14.0436 18.7367 14.1553 19.0625 14.3523 19.3239C14.553 19.5814 14.8201 19.7765 15.1534 19.9091C15.4905 20.0379 15.8712 20.1023 16.2955 20.1023C16.7121 20.1023 17.0739 20.0417 17.3807 19.9205C17.6913 19.7992 17.9318 19.6307 18.1023 19.4148C18.2727 19.1989 18.358 18.9508 18.358 18.6705C18.358 18.4091 18.2803 18.1894 18.125 18.0114C17.9735 17.8333 17.75 17.6818 17.4545 17.5568C17.1629 17.4318 16.8049 17.3182 16.3807 17.2159L15.1136 16.8977C14.1326 16.6591 13.358 16.286 12.7898 15.7784C12.2216 15.2708 11.9394 14.5871 11.9432 13.7273C11.9394 13.0227 12.1269 12.4072 12.5057 11.8807C12.8883 11.3542 13.4129 10.9432 14.0795 10.6477C14.7462 10.3523 15.5038 10.2045 16.3523 10.2045C17.2159 10.2045 17.9697 10.3523 18.6136 10.6477C19.2614 10.9432 19.7652 11.3542 20.125 11.8807C20.4848 12.4072 20.6705 13.017 20.6818 13.7102H18.3239Z"
          fill={props.color}
        />
      );
      break;
    case "R":
      content = (
        <path
          d="M12.0114 22V10.3636H16.6023C17.4811 10.3636 18.2311 10.5208 18.8523 10.8352C19.4773 11.1458 19.9527 11.5871 20.2784 12.1591C20.608 12.7273 20.7727 13.3958 20.7727 14.1648C20.7727 14.9375 20.6061 15.6023 20.2727 16.1591C19.9394 16.7121 19.4564 17.1364 18.8239 17.4318C18.1951 17.7273 17.4337 17.875 16.5398 17.875H13.4659V15.8977H16.142C16.6117 15.8977 17.0019 15.8333 17.3125 15.7045C17.6231 15.5758 17.8542 15.3826 18.0057 15.125C18.161 14.8674 18.2386 14.5473 18.2386 14.1648C18.2386 13.7784 18.161 13.4527 18.0057 13.1875C17.8542 12.9223 17.6212 12.7216 17.3068 12.5852C16.9962 12.4451 16.6042 12.375 16.1307 12.375H14.4716V22H12.0114ZM18.2955 16.7045L21.1875 22H18.4716L15.642 16.7045H18.2955Z"
          fill={props.color}
        />
      );
      break;
    case "P":
      content = (
        <path
          d="M12.0114 22V10.3636H16.6023C17.4848 10.3636 18.2367 10.5322 18.858 10.8693C19.4792 11.2027 19.9527 11.6667 20.2784 12.2614C20.608 12.8523 20.7727 13.5341 20.7727 14.3068C20.7727 15.0795 20.6061 15.7614 20.2727 16.3523C19.9394 16.9432 19.4564 17.4034 18.8239 17.733C18.1951 18.0625 17.4337 18.2273 16.5398 18.2273H13.6136V16.2557H16.142C16.6155 16.2557 17.0057 16.1742 17.3125 16.0114C17.6231 15.8447 17.8542 15.6155 18.0057 15.3239C18.161 15.0284 18.2386 14.6894 18.2386 14.3068C18.2386 13.9205 18.161 13.5833 18.0057 13.2955C17.8542 13.0038 17.6231 12.7784 17.3125 12.6193C17.0019 12.4564 16.608 12.375 16.1307 12.375H14.4716V22H12.0114Z"
          fill={props.color}
        />
      );
      break;
    case "IP":
      content = (
        <path
          d="M13.4716 10.3636V22H11.0114V10.3636H13.4716ZM15.4957 22V10.3636H20.0866C20.9692 10.3636 21.7211 10.5322 22.3423 10.8693C22.9635 11.2027 23.437 11.6667 23.7628 12.2614C24.0923 12.8523 24.2571 13.5341 24.2571 14.3068C24.2571 15.0795 24.0904 15.7614 23.7571 16.3523C23.4238 16.9432 22.9408 17.4034 22.3082 17.733C21.6795 18.0625 20.9181 18.2273 20.0241 18.2273H17.098V16.2557H19.6264C20.0999 16.2557 20.4901 16.1742 20.7969 16.0114C21.1075 15.8447 21.3385 15.6155 21.4901 15.3239C21.6454 15.0284 21.723 14.6894 21.723 14.3068C21.723 13.9205 21.6454 13.5833 21.4901 13.2955C21.3385 13.0038 21.1075 12.7784 20.7969 12.6193C20.4863 12.4564 20.0923 12.375 19.6151 12.375H17.956V22H15.4957Z"
          fill={props.color}
        />
      );
      break;
    default:
      break;
  }

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="15.5" fill="white" />
      {content}
      <circle cx="16" cy="16" r="15.5" stroke={props.color} />
    </svg>
  );
}
