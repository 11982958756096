import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";

interface Params {
  title: string;
  text: string;
  onConfirm: Function;
  onCancel: Function;
}

export default function ConfirmModal({
  title,
  text,
  onConfirm,
  onCancel,
}: Params) {
  return (
    <>
      <Box p={2}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>

        <Typography variant="body1" color="text.secondary">
          {text}
        </Typography>

        <Stack mt={2} direction="row" justifyContent={"flex-end"} spacing={2}>
          <Button variant="outlined" onClick={() => onCancel()} size="small">
            Abbrechen
          </Button>

          <Button variant="contained" onClick={() => onConfirm()} size="small">
            Bestätigen
          </Button>
        </Stack>
      </Box>
    </>
  );
}
